import { LitElement, html } from 'lit';

class TimelineMapMarker extends LitElement {
  static get is() { return 'timeline-map-marker'; }

  render() {
    return html`
        <style>
          :host {
            display: block;
            padding: 0;
          }
        </style>
        <slot @timeline-map-element-attached="${this.updateMarkerElements}" @slotchange="${this.updateMarkerElements}"></slot>
    `;
  }

  static get properties() {
    return {
      coordinates: {
        type: Array,
      },
      color: {
        type: String,
      },
      offset: {
        type: Array,
      },
    };
  }

  set map(map) {
    if (map !== this._map) {
      this._map = map;
      // eslint-disable-next-line no-unused-expressions
      map ? this.initMarker(map) : (this.marker && this.marker.remove());
      this.updateMarkerElements();
    }
  }

  get map() {
    return this._map;
  }

  set color(color) {
    this.initialMarkerOptions.color = color;
  }

  get color() {
    return this.initialMarkerOptions.color;
  }

  set coordinates(lngLatLike) {
    // eslint-disable-next-line no-unused-expressions
    this.marker && this.marker.setLngLat(lngLatLike);
    this._lngLat = lngLatLike;
  }

  get coordinates() {
    return this.marker ? this.marker.getLngLat() : this._lngLat;
  }

  set element(element) {
    this.initialMarkerOptions.element = element;
  }

  get element() {
    return this.marker ? this.marker.getElement() : this.initialMarkerOptions.element;
  }

  constructor(props) {
    super(props);
    this.initialMarkerOptions = {
      element: null,
      offset: [0, 0],
      color: '#015b86',
    };
    this._lngLat = [0, 0];
    this._map = null;
    this.marker = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.dispatchEvent(new CustomEvent('timeline-map-element-attached', { detail: this, bubbles: true, composed: true }));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // eslint-disable-next-line no-unused-expressions
    this.marker && this.marker.remove();
  }

  initMarker(map) {
    // eslint-disable-next-line no-undef
    this.marker = new mapboxgl.Marker(this.initialMarkerOptions).setLngLat(this._lngLat);
    // eslint-disable-next-line dot-notation,no-unused-expressions
    !this._element && (this.marker.getElement().style['position'] = 'absolute');
    this.marker.addTo(map);
  }

  updateMarkerElements() {
    const shadowRootSlot = this.shadowRoot.querySelector('slot');

    if (shadowRootSlot) {
      const nodes = this.shadowRoot.querySelector('slot').assignedNodes({ flatten: true });
      const mapElements = nodes.filter((n) => n.nodeName.slice(0, 12) === 'TIMELINE-MAP');

      if (this.marker) {
        // eslint-disable-next-line no-param-reassign,no-return-assign
        mapElements.forEach((n) => n.marker = this);
      }

      this.element = nodes.find((n) => n instanceof HTMLElement && n.nodeName.slice(0, 12) !== 'TIMELINE-MAP');
    }
  }
}

customElements.define(TimelineMapMarker.is, TimelineMapMarker);
