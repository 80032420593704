import {
  ADD_LOGIN_ERROR_MESSAGE,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
} from '../constants/auth';
import { setCurrentPage } from './app';

export const addLoginErrorMessage = (error) => {
  return {
    type: ADD_LOGIN_ERROR_MESSAGE,
    payload: error,
  };
};

export const setLoggedOut = () => {
  return {
    type: SET_LOGGED_OUT,
  };
};

export const userJustLoggedIn = () => {
  return (dispatch) => {
    dispatch({
      type: SET_LOGGED_IN,
    });
    dispatch(setCurrentPage('timeline'));
  };
};

/**
 * When we logout we need to unsubscribe to the snapshot listeners
 */
export const unsetListeners = () => {

};
