import { LitElement, html } from 'lit';
import '../timeline-teaser-icon/timeline-teaser-icon';

// eslint-disable-next-line import/extensions
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import styles from './timeline-teaser.css';
import {
  MODAL_ASPECT_DESCRIPTION,
  MODAL_ASPECT_INFOS,
} from '../../types/modal-aspect';
import { ICON_NAME, ICON_SIZE } from '../../types/icon-types';
import { MODAL_ENTRY_TYPES } from '../../types/modal-entry-types';

class TimelineTeaser extends LitElement {
  /**
   * Render the teaser
   * There are different kinds of teasers:
   *    - normal teaser
   *    - tod0 teaser
   *    - layover teaser
   */
  render() {
    if (this.entry.type === 'layover') {
      return this._renderLayoverTeaser();
    }
    if (this.entry.type === 'todo') {
      return this._renderTodoTeaser();
    }
    return this._renderNormalTeaser();
  }

  static get is() {
    return 'timeline-teaser';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      entry: {
        type: Object,
      },
      todoDone: {
        type: Boolean,
      },
      hasInfos: {
        type: Boolean,
        reflect: true,
      },
      openTeaser: {
        type: Boolean,
      },
      isPast: {
        type: Boolean,
      },
      feature: {
        type: Object,
      },
    };
  }

  constructor() {
    super();
    this.entry = {};
    this.pageLink = '';
    this.todoDone = false;
    this.feature = null;
    this.openTeaser = false;
  }

  /**
   * Update state of tod0 in a todo0 teaser
   */
  _updateTodoState(event) {
    this.dispatchEvent(new CustomEvent(event.target.checked ? 'check-todo' : 'uncheck-todo', {
      bubbles: true,
      composed: true,
      detail: this.entry,
    }));
  }

  /**
   * Open or close the teaser's info section when the teaser's top section is clicked
   */
  _handleTopClick(event) {
    if (!event.target.classList.contains('timeline-teaser__top-link')) {
      this.toggleAttribute('open');
      this.openTeaser = this.hasAttribute('open');
    }
  }

  /**
   *
   * @param {string} aspect
   * @private
   */
  _handleActionClick(aspect) {
    this.dispatchEvent(new CustomEvent('open-modal', {
      bubbles: true,
      composed: true,
      detail: {
        entry: this.entry,
        aspect,
        feature: this.feature,
      },
    }));
  }

  /**
   * Open a modal
   */
  _openModal(pageLink, modalType) {
    if (modalType === 'page' || modalType === 'image') {
      this.dispatchEvent(new CustomEvent('open-modal', {
        bubbles: true,
        composed: true,
        detail: {
          pageType: modalType,
          pageLink,
        },
      }));
    }
  }

  _renderLayoverTeaser() {
    return html`
      <div class="timeline-teaser__icon-container timeline-teaser__icon-container--layover" aria-hidden="true">
        <timeline-teaser-icon iconType="layover"></timeline-teaser-icon>
      </div>
      <div class="timeline-teaser__top timeline-teaser__right--layover">
        <div class="timeline-teaser__top-info">
          <p class="timeline-teaser__title">${this.entry.headline}</p>
        </div>
      </div>
    `;
  }

  _renderNormalTeaser() {
    return html`
      <div class="timeline-teaser__icon-container" aria-hidden="true">
        <timeline-teaser-icon iconType="${this.entry.type}" ?isPast="${this.isPast}"></timeline-teaser-icon>
      </div>

      <div class="timeline-teaser__right">
        <div class="timeline-teaser__top" @click="${this._handleTopClick}" role="button" aria-expanded="${this.openTeaser}">
          <div class="timeline-teaser__top-info">
            <h3 class="timeline-teaser__title">${this.entry.headline}</h3>
            ${this.entry.subtitle
    ? html`
<p class="timeline-teaser__subtitle">${unsafeHTML(this.entry.subtitle)}</p>`
    : this.setAttribute('teaser-without-subtitle', '')
}
          </div>
          <div class="timeline-teaser__map-symbol-container">
            ${this.feature ? html`
              <timeline-icon
                class="timeline-group__header-chevron"
                icon-name="${ICON_NAME.LOCATION}"
                icon-size="${ICON_SIZE.M}"
                @click="${this._handlePinClick}"
              ></timeline-icon>
            ` : ''}
          </div>
          <div class="timeline-teaser__chevron-container">
            ${this.entry.infos ? html`
              <timeline-icon class="timeline-group__header-chevron" icon-name="${ICON_NAME.CHEVRON_DOWN}" icon-size="${ICON_SIZE.M}"></timeline-icon>
            ` : ''}
          </div>
        </div>

        ${this.entry.infos ? this._renderBottomInfos(this.entry.infos) : ''}
        ${this.entry.actions ? this._renderActions(this.entry.actions) : ''}
      </div>
    `;
  }

  // eslint-disable-next-line consistent-return, no-unused-vars
  _renderActions(actions) {
    const entryTypesWithActions = [
      MODAL_ENTRY_TYPES.CAR,
      MODAL_ENTRY_TYPES.HOTEL,
      ...MODAL_ENTRY_TYPES.getAdventureTypes(),
    ];

    if (entryTypesWithActions.includes(this.entry?.type)) {
      return html`
        <div class="timeline-teaser__actions">
            <button @click="${() => this._handleActionClick(MODAL_ASPECT_INFOS)}"
                    class="timeline-teaser__action"
            >Buchung</button>
            ${this.feature && this.entry.type === MODAL_ENTRY_TYPES.HOTEL
    ? html`<button @click="${() => this._handleActionClick(MODAL_ASPECT_DESCRIPTION)}"
                            class="timeline-teaser__action"
              >Informationen</button>`
    : ''
}
          ${this.feature?.latitude
    ? html`<button @click="${() => this._handleDirectionsClick()}"
                            class="timeline-teaser__action"
              >Route</button>`
    : ''
}
        </div>
        `;
    }
  }

  _renderBottomInfos(infos) {
    return html`<div class="timeline-teaser__bottom" ?aria-hidden="${!this.openTeaser}">
      ${unsafeHTML(infos)}
    </div>`;
  }

  _renderTodoTeaser() {
    return html`
      <div class="timeline-teaser__icon-container">
        <timeline-teaser-icon icontype="todo" ?isPast="${this.isPast}"></timeline-teaser-icon>
      </div>

      <div class="timeline-teaser__right timeline-teaser__right--todo">
        <div class="timeline-teaser__top ">
          <div class="timeline-teaser__top-info">
            <h3 class="timeline-teaser__title">${this.entry.headline}</h3>
            ${this.entry.subtitle
    ? html`<p class="timeline-teaser__subtitle">${this.entry.subtitle}</p>`
    : this.setAttribute('teaser-without-subtitle', '')
}
            ${this.entry.actions ? this._renderActions(this.entry.actions) : ''}
          </div>
          <div class="timeline-teaser__checkmark-container">
            <input id="check" type="checkbox" class="timeline-teaser__todo-input" role="checkbox" @change="${this._updateTodoState}" .checked="${this.todoDone}">
            <label for="check" class="timeline-teaser__todo-label">
              <span class="timeline-teaser__check-container">
                ${this._renderCheckboxIcon()}
              </span>
            </label>
          </div>
        </div>
      </div>
    `;
  }

  _renderCheckboxIcon() {
    if (this.todoDone) {
      return html`
        <timeline-icon class="timeline-group__header-chevron" icon-name="${ICON_NAME.CHECKBOX_CHECKED}" icon-size="${ICON_SIZE.M}"></timeline-icon>
      `;
    }
    return html`
      <timeline-icon class="timeline-group__header-chevron" icon-name="${ICON_NAME.CHECKBOX_UNCHECKED}" icon-size="${ICON_SIZE.M}"></timeline-icon>
    `;
  }

  _handleDirectionsClick() {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${this.feature.latitude},${this.feature.longitude}`,
      '_blank',
    );
  }

  /**
   * @param {MouseEvent} e
   * @private
   */
  _handlePinClick(e) {
    e.stopPropagation();

    this.dispatchEvent(new CustomEvent('set-open-popup-id', {
      bubbles: true,
      composed: true,
      detail: {
        teaserID: this.feature.identifier,
      },
    }));
  }
}

window.customElements.define(TimelineTeaser.is, TimelineTeaser);
