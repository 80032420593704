export const ICON_NAME = {
  ADDRESS: 'address',
  BED: 'bed',
  BELL: 'bell',
  BICYCLE: 'bicycle',
  BIRD_WATCHING: 'bird-watching',
  BOAT_TOUR: 'boat-tour',
  BOOK_OPEN: 'book-open',
  BOOKING: 'booking',
  BURGER: 'burger',
  CALENDAR_MONTH: 'calendar-month',
  CANOEING: 'canoeing',
  CANYONING: 'canyoning',
  CAR: 'car',
  CAVE: 'cave',
  CHECKBOX_CHECKED: 'checkbox-checked',
  CHECKBOX_UNCHECKED: 'checkbox-unchecked',
  CHECKMARK: 'checkmark',
  CHEVRON_DOWN: 'chevron-down',
  CHURCH: 'church',
  CITY: 'city',
  CLIMATE: 'climate',
  CLOSE: 'close',
  DIVING: 'diving',
  DOLPHINS: 'dolphins',
  EMAIL: 'email',
  END: 'end',
  EVENT: 'event',
  FAMILY: 'family',
  FERRY: 'ferry',
  FLIGHT: 'flight',
  GOLF: 'golf',
  GUIDED_TOUR: 'guided-tour',
  HIKING: 'hiking',
  HOTEL: 'hotel',
  HOTSPRING: 'hotspring',
  INFO: 'info',
  KAYAKING: 'kayaking',
  LAKE: 'lake',
  LOCATION: 'location',
  MAP: 'map',
  MARINA: 'marina',
  MEETINGPOINT: 'meetingpoint',
  MUSEUM: 'museum',
  NATURE: 'nature',
  PASSPORT: 'passport',
  PDF: 'pdf',
  PEOPLE: 'people',
  PHONE: 'phone',
  PLANTATION: 'plantation',
  RESTAURANT: 'restaurant',
  RIDING: 'riding',
  SAILING: 'sailing',
  SUPERMARKET: 'supermarket',
  SUSTAINABLE: 'sustainable',
  TODO: 'todo',
  TRAIN: 'train',
  TRANSFER: 'transfer',
  VIEWPOINT: 'viewpoint',
  VILLAGE: 'village',
  WATERFALL: 'waterfall',
  WELLNESS: 'wellness',
  WHALEWATCHING: 'whalewatching',
  YOGA: 'yoga',
};

export const ICON_SIZE = {
  S: 's',
  NORMAL: 'normal',
  MS: 'ms',
  M: 'm',
  L: 'l',
  XL: 'xl',
};
