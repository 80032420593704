import { CLOSE_MODAL, OPEN_MODAL } from '../constants/modal';

const initialState = {
  open: false,
  loading: false,
  aspect: null,
  entry: null,
  feature: null,
  additionalFeatures: [],
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        open: true,
        aspect: action.payload.aspect,
        entry: action.payload.entry,
        feature: action.payload.feature,
        additionalFeatures: action.payload.additionalFeatures ?? [],
      };
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
}
