import { LitElement, html } from 'lit';
import styles from './timeline-teaser-icon.css';
import { ICON_NAME, ICON_SIZE } from '../../types/icon-types';

class TimelineTeaserIcon extends LitElement {
  render() {
    return html`
      <timeline-icon
        class="timeline-teaser-icon__icon"
        icon-name="${ICON_NAME[this._getIconName(this.icontype)]}"
        icon-size="${ICON_SIZE.M}"
      ></timeline-icon>
  `;
  }

  static get is() {
    return 'timeline-teaser-icon';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      icontype: { type: String },
    };
  }

  constructor() {
    super();
    this.icontype = '';
  }

  // eslint-disable-next-line class-methods-use-this
  _getIconName(iconType) {
    return iconType.toUpperCase().replace('-', '_');
  }
}

window.customElements.define(TimelineTeaserIcon.is, TimelineTeaserIcon);
