import { LitElement, html } from 'lit';
import styles from './timeline-icon.css';

class TimelineIcon extends LitElement {
  static get is() {
    return 'timeline-icon';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      iconSize: {
        type: String,
        attribute: 'icon-size',
      },
      iconColor: {
        type: String,
        attribute: 'icon-color',
      },
      iconName: {
        type: String,
        attribute: 'icon-name',
      },
    };
  }

  constructor() {
    super();
    this.iconSize = 'normal';
    this.iconColor = 'text';
    this.iconName = '';
  }

  render() {
    return html`
      <i class="icon" icon-size="${this.iconSize}" icon-color="${this.iconColor}" icon-name="${this.iconName}"></i>
    `;
  }
}

window.customElements.define(TimelineIcon.is, TimelineIcon);
