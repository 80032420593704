import { configureStore } from '@reduxjs/toolkit';
import authReducer from './redux/reducers/auth';
import timelineReducer from './redux/reducers/timeline';
import notificationsReducer from './redux/reducers/notifications';
import appReducer from './redux/reducers/app';
import mapReducer from './redux/reducers/map';
import modalReducer from './redux/reducers/modal';
import destinationPagesReducer from './redux/reducers/destination-pages';

// Automatically adds the thunk middleware and the Redux DevTools extension
export const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    app: appReducer,
    auth: authReducer,
    map: mapReducer,
    timeline: timelineReducer,
    notifications: notificationsReducer,
    modal: modalReducer,
    destinationPages: destinationPagesReducer,
  },
});
