import * as _ from 'lodash-es';
import { store } from './store';
import { closeModal, openModal } from './redux/actions/modal';

window.addEventListener('popstate', (event) => {
  if (_.isEmpty(event.state) === false) {
    store.dispatch(openModal(event.state, false));
  } else {
    store.dispatch(closeModal(false));
  }
});
