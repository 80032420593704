import { LitElement, html } from 'lit';
import styles from './timeline-header.css';

class TimelineHeader extends LitElement {
  render() {
    return html`
      ${this.image !== 'undefined'
    ? html`<img src="${this.image}" class="timeline-header__image"/>`
    : ''
}
      <h2 class="timeline-header__title">${this.headline}</h2>
      <p class="timeline-header__period">${this.period}</p>
  `;
  }

  static get is() {
    return 'timeline-header';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      headline: {
        type: String,
      },
      period: {
        type: String,
      },
      image: {
        type: String,
      },
    };
  }

  constructor() {
    super();
    this.headline = 'Loading';
    this.period = 'loading';
    this.image = '';
  }
}

window.customElements.define(TimelineHeader.is, TimelineHeader);
