import {
  SET_MAP_BOUNDS,
  SET_MAP_FEATURES,
  SET_TEASER_TO_HIGHLIGHT,
  UNSET_TEASER_TO_HIGHLIGHT,
  HIDE_MOBILE_MAP, SHOW_MOBILE_MAP, SET_OPEN_POPUP_ID, UNSET_OPEN_POPUP_ID,
} from '../constants/map';

const initialState = {
  mapBounds: [
    ['-33.6680439711', '26.0976619174'],
    ['-10.7121574879', '41.768767343'],
  ],
  mapView: false,
  mapFeatures: null,
  teaserToHighlight: null,
  openPopupId: null,
};

export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MAP_FEATURES:
      return {
        ...state,
        mapFeatures: action.payload,
      };
    case SET_MAP_BOUNDS:
      return {
        ...state,
        mapBounds: action.payload,
      };

    case SET_TEASER_TO_HIGHLIGHT:
      return {
        ...state,
        teaserToHighlight: action.payload.teaserID,
      };

    case UNSET_TEASER_TO_HIGHLIGHT:
      return {
        ...state,
        teaserToHighlight: null,
      };
    case SHOW_MOBILE_MAP:
      return {
        ...state,
        mapView: true,
      };

    case HIDE_MOBILE_MAP:
      return {
        ...state,
        mapView: false,
      };
    case SET_OPEN_POPUP_ID:
      return {
        ...state,
        openPopupId: action.payload,
      };
    case UNSET_OPEN_POPUP_ID:
      return {
        ...state,
        openPopupId: null,
      };
    default:
      return state;
  }
}
