import { LitElement, html } from 'lit';
import styles from './timeline-group.css';
import { ICON_NAME, ICON_SIZE } from '../../types/icon-types';

class TimelineGroup extends LitElement {
  render() {
    if (this.group.name === 'end') {
      return this._renderEndGroup();
    }

    return this._renderNormalGroup();
  }

  static get styles() {
    return styles;
  }

  static get is() {
    return 'timeline-group';
  }

  static get properties() {
    return {
      entries: {
        type: Array,
      },
      features: {
        type: Object,
      },
      group: {
        type: Object,
      },
      open: {
        type: Boolean,
      },
      teaserStates: {
        type: Object,
      },
      groupDate: {
        type: String,
      },
      teaserToHighlight: {
        type: String,
      },
      teaserWithOpenPopup: {
        type: String,
      },
      isInPast: {
        type: Boolean,
        attribute: 'ispast',
      },

    };
  }

  constructor() {
    super();
    this.entries = [];
    this.group = {};
    this.teaserStates = {};
    this.features = {};
    this.teaserToHighlight = null;
    this.teaserWithOpenPopup = null;
    this.isInPast = false;
  }

  /**
   * Get width of progress bar
   * as a percentage of "done' todos
   */
  _getProgressBarWidth() {
    // eslint-disable-next-line no-mixed-operators, max-len
    return (this.getTodos.filter((entry) => entry.todoDone === true).length / this.getTodos.length * 100);
  }

  /**
   * Render end group
   * The end groups has no teasers, just a 'thank you' message
   */
  _renderEndGroup() {
    return html`
      <div class="timeline-group__teasers">
        ${this.entries
    ? html`${this.entries.map((entry) => html`<timeline-teaser .entry="${entry}" teaser-type="${entry.type}"></timeline-teaser>`)}`
    : html`<p>Loading</p>`}
      </div>
    `;
  }

  /**
   * Render normal group with teasers
   * If groups with todos, then render also the todos progress bar
   */
  _renderNormalGroup() {
    return html`
      <div class="timeline-group__header" @click="${this._handleHeaderClick}" aria-expanded="${this.open}" role="button">
        <h3 class="timeline-group__title"><span class="timeline-group__title-span">${this.group.name}</span></h3>
        ${!this.open
    ? html`<div class="timeline-group__small-icons">${this.entries.map((entry) => html`<div class="timeline-group__small-icon" type="${entry.type}">${this._renderSmallIcon(entry.type)}</div>`)}</div>`
    : html`<div></div>`
}
        <timeline-icon class="timeline-group__header-chevron" icon-name="${ICON_NAME.CHEVRON_DOWN}" icon-size="${ICON_SIZE.M}"></timeline-icon>
      </div>
      <div class="timeline-group__teasers">
        ${this.entries
    ? html`${this.entries.map((entry) => html`
            <timeline-teaser
              role="article"
              .entry="${entry}"
              .feature="${this._getFeature(entry.ref)}"
              teaser-type="${entry.type}"
              ?todoDone="${entry?.todoDone}"
              ?isPast="${this.isInPast}"
              ?highlighted="${this._checkIfTeaserIsHighlighted(entry.ref)}"
              ?hasinfos="${entry?.infos}"
            ></timeline-teaser>`)}`
    : html`<p>Loading</p>`}

        ${this.getTodos.length > 0
    ? html`<div role="alert"><progress class="timeline-group__progessbar" max="100" value="${this._getProgressBarWidth()}" aria-valuemin="0" aria-valuemax="100" aria-valuenow="${this._getProgressBarWidth()}" role="progressbar"></progress></div>`
    : ''
}
      </div>
    `;
  }

  /**
   * Check if teaser if highlighted
   * A teaser is highlighted either when:
   *    - corresponding feature on map is hovered
   *    - corresponding popup is open
   */
  _checkIfTeaserIsHighlighted(reference) {
    if (reference === null || reference === '') {
      return false;
    }

    const isHighlighted = this.teaserToHighlight === reference;
    const isPopupOpen = this.teaserWithOpenPopup === reference;
    return (isHighlighted || isPopupOpen);
  }

  _getFeature(reference) {
    if (reference === null || reference === '') {
      return null;
    }

    return this.features[reference] ?? null;
  }

  /**
   * Close or open group when the group's header is clicked
   */
  _handleHeaderClick() {
    // eslint-disable-next-line no-unused-expressions
    this.open ? this._closeGroup() : this._openGroup();
  }

  /**
   * Send action to open the group
   */
  _openGroup() {
    const event = new CustomEvent('open-group', {
      bubbles: true,
      composed: true,
      detail: {
        groupID: this.group.group_id,
      },
    });
    this.dispatchEvent(event);
  }

  /**
   * Send action to close the group
   */
  _closeGroup() {
    const event = new CustomEvent('close-group', {
      bubbles: true,
      composed: true,
      detail: {
        groupID: this.group.group_id,
      },
    });
    this.dispatchEvent(event);
  }

  /**
   * Render small icons
   * Small icons are used on the group header when the group is close
   * (to show which entry types the menu has)
   */
  _renderSmallIcon(type) {
    return html`
      <timeline-teaser-icon icontype="${type}" small-icons ?isPast="${this.isInPast}"></timeline-teaser-icon>
    `;
  }

  get getTodos() {
    return this.entries.filter((entry) => entry.type === 'todo');
  }
}

window.customElements.define(TimelineGroup.is, TimelineGroup);
