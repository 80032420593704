import { LitElement, html } from 'lit';
import styles from './timeline-spinner.css';

class TimelineSpinner extends LitElement {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return html``;
  }

  static get is() {
    return 'timeline-spinner';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {};
  }

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }
}

window.customElements.define(TimelineSpinner.is, TimelineSpinner);
