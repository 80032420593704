import {
  CLOSE_ALL_GROUPS, OPEN_CURRENT_GROUPS, CLOSE_GROUP,
  OPEN_ALL_GROUPS, OPEN_GROUP,
  SET_TIMELINE,
  SET_TIMELINE_ENTRIES_REFERENCE_DATA,
  CHECK_TODO, UNCHECK_TODO,
} from '../constants/timeline';
import { isPastGroup } from '../../lib/utils';

const initialState = {
  timeline: {},
  features: {},
  openGroups: [],
};
export default function timelineReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TIMELINE:
      return {
        ...state,
        timeline: action.payload,
      };
    case SET_TIMELINE_ENTRIES_REFERENCE_DATA:
      return {
        ...state,
        features: action.payload,
      };
    case OPEN_CURRENT_GROUPS:
      return {
        ...state,
        openGroups: state.timeline.groups
          .filter((group) => group.group_id === 'end' || !isPastGroup(group.group_id))
          .map((group) => group.group_id),
      };
    case OPEN_ALL_GROUPS:
      return {
        ...state,
        openGroups: state.timeline.groups.map((group) => group.group_id),
      };
    case CLOSE_ALL_GROUPS: {
      return {
        ...state,
        openGroups: [],
      };
    }
    case CLOSE_GROUP: {
      return {
        ...state,
        openGroups: state.openGroups.filter((groupID) => groupID !== action.payload.groupID),
      };
    }
    case OPEN_GROUP: {
      return {
        ...state,
        openGroups: [
          ...state.openGroups,
          action.payload.groupID,
        ],
      };
    }
    case CHECK_TODO:
    case UNCHECK_TODO:
      const todoDone = action.type === CHECK_TODO;

      const timelineEntries = (state.timeline?.entries ?? []).map(
        (timelineEntry) => {
          if (timelineEntry.$id === action.payload.$id) {
            return {
              ...timelineEntry,
              todoDone,
            };
          }
          return timelineEntry;
        },
      );

      return {
        ...state,
        timeline: {
          ...state.timeline,
          entries: timelineEntries,
        },
      };
    default:
      return state;
  }
}
