import { LitElement, html } from 'lit';
import styles from './timeline-topbar.css';
import '../timeline-menu/timeline-menu';
import { ICON_NAME, ICON_SIZE } from '../../types/icon-types';

class TimelineTopbar extends LitElement {
  /**
   * render topbar with:
   *    - Menu button
   *    - Logo
   *    - Action buttons (on desktop)
   */
  render() {
    return html`
      <img src="/images/logos/seabreeze-logo.svg" alt="Seabreeze Travel logo" class="timeline-topbar__logo" @click="${this._onLogoClick}">

      <timeline-menu
        ?open="${this.openMenu}"
        ?openModal="${this.openModal}"
        currentPage="${this.currentPage}"
        .menuSections="${this.menuSections}"
      ></timeline-menu>

      ${(this.currentPage === 'timeline' && !this.openMenu)
    ? html`
          <div class="timeline-topbar__actions-container">
            <div
              class="timeline-topbar__action-button"
              role="button"
              aria-expanded="${this.notificationsOpen}"
              @click="${this._handleNotificationsClick}"
              ?active="${this.notificationsOpen}"
              ?hidden="${!this._checkIfPushSupport()}"
            >
              <timeline-icon class="timeline-topbar__action-svg" icon-name="${ICON_NAME.BELL}" icon-size="${ICON_SIZE.L}"></timeline-icon>
              <span class="timeline-topbar__notification-badge" ?show="${this.notificationsAmount > 0}">${this.notificationsAmount}</span>
            </div>

        </div>
        ` : ''
}
    `;
  }

  static get is() {
    return 'timeline-topbar';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      openMenu: {
        type: Boolean,
      },
      currentPage: {
        type: String,
      },
      openModal: {
        type: Boolean,
      },
      menuSections: {
        type: Array,
      },
      appMobile: {
        type: Boolean,
      },
      notificationsAmount: {
        type: Number,
      },
      notificationsOpen: {
        type: Boolean,
      },
      mapVisible: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.openMenu = false;
    this.currentPage = '';
    this.openModal = false;
    this.appMobile = false;
    this.notificationsAmount = 0;
    this.notificationsOpen = false;
    this.mapVisible = false;
  }

  _handleNotificationsClick() {
    this.dispatchEvent(new CustomEvent(this.notificationsOpen ? 'close-notifications' : 'open-notifications', {
      bubbles: true,
      composed: true,
    }));
    this._closeMenuAndFilters();
  }

  /**
   * Close menu and filters
   */
  _closeMenuAndFilters() {
    this.dispatchEvent(new CustomEvent('close-menu', {
      bubbles: true,
      composed: true,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  _checkIfPushSupport() {
    return 'PushManager' in window;
  }

  _onLogoClick() {
    this.dispatchEvent(new CustomEvent('close-modal', {
      bubbles: true,
      composed: true,
    }));
  }
}

window.customElements.define(TimelineTopbar.is, TimelineTopbar);
