import { LitElement, html } from 'lit';
import styles from './timeline-login.css';
import { timelineLogin } from '../../auth';
import '../timeline-spinner/timeline-spinner';

// eslint-disable-next-line import/prefer-default-export
export class TimelineLogin extends LitElement {
  static get is() {
    return 'timeline-login';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      inputEmail: {
        type: String,
      },
      inputResetEmail: {
        type: String,
      },
      inputPassword: {
        type: String,
      },
      loginErrorMessage: {
        type: String,
      },
      falseLogin: {
        type: Boolean,
      },
      showSpinner: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.loginTitle = 'Atlantische Inseln Entdecken';
    this.placeholderBookingNumber = 'Buchungsnummer';
    this.placeholderFamilyName = 'Nachname';
    this.inputEmail = '';
    this.inputPassword = '';
    this.falseLogin = false;
    this.loginErrorMessage = '';
    this.showSpinner = false;
  }

  render() {
    return html`
      <img src="/images/logos/seabreeze-logo.svg" alt="Seabreeze Travel logo" class="timeline-login__logo">

      <h1 class="timeline-login__title">${this.loginTitle}</h1>
      ${this._renderLoginView()}
    `;
  }

  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this._handleLoginClick();
    }
  }

  /**
   * Check if input is empty of has value to hide or show the input tips
   */
  // eslint-disable-next-line class-methods-use-this
  _checkIfInputHasValue(event) {
    if (event.target.value) {
      event.target.classList.add('timeline-login__input-tip--active');
    } else {
      event.target.classList.remove('timeline-login__input-tip--active');
    }
  }

  _handleLoginClick() {
    this._handleSpinnerShow();

    const bookingNumber = this.shadowRoot.querySelector('#inputBookingNumber').value;
    const familyName = this.shadowRoot.querySelector('#inputFamilyName').value;
    timelineLogin(bookingNumber, familyName);
  }

  _handleSpinnerShow() {
    this.dispatchEvent(new CustomEvent('spinner-show', {
      bubbles: true,
      composed: true,
    }));
  }

  _renderLoginView() {
    return html`
      <form class="timeline-login__card" aria-label="Login Form">
        <h3 class="timeline-login__trip-title">Login</h3>
        <div class="timeline-login__input-group" ?hidden="${this.showSpinner && !this.falseLogin}">
          <input
            type="text"
            class="timeline-login__input"
            id="inputBookingNumber"
            .value="${this.inputEmail}"
            ?error="${this.falseLogin}"
            @input="${this._checkIfInputHasValue}"
            @keypress="${this._handleKeyPress}"
            required
            autocomplete="on"
            aria-label="${this.placeholderBookingNumber}"
          />
          <label for="inputBookingNumber"
                 class="timeline-login__input-tip">${this.placeholderBookingNumber}</label>
          <span class="timeline-login__input-bar" aria-hidden="true"></span>
        </div>

        <div class="timeline-login__input-group" ?hidden="${this.showSpinner && !this.falseLogin}">
          <input
            type="text"
            class="timeline-login__input"
            id="inputFamilyName"
            .value="${this.inputPassword}"
            ?error="${this.falseLogin}"
            @input="${this._checkIfInputHasValue}"
            @keypress="${this._handleKeyPress}"
            required
            autocomplete="on"
            aria-label="${this.placeholderFamilyName}"
          />
          <label for="inputFamilyName"
                 class="timeline-login__input-tip">${this.placeholderFamilyName}</label>
          <span class="timeline-login__input-bar" aria-hidden="true"></span>
        </div>

        <div class="timeline-login__button-container"
             ?hidden="${this.showSpinner && !this.falseLogin}">
          <timeline-button
            class="timeline-login__button"
            button-variant="primary"
            button-type="submit"
            @click="${this._handleLoginClick}"
          "
          >Login</timeline-button>
        </div>

        ${this.falseLogin ? html`
          <div class="timeline-login__error-container">
            <span
              class="timeline-login__error-message"
              role="alert"
            >${this.loginErrorMessage}</span>
          </div>` : ''}

        ${this.showSpinner && !this.falseLogin ? html`
          <div class="timeline-login__spinner-container">
            <timeline-spinner></timeline-spinner>
          </div>` : ''}
      </form>
    `;
  }
}

window.customElements.define(TimelineLogin.is, TimelineLogin);
