import { store } from './store';
import {
  addLoginErrorMessage,
  setLoggedOut,
  userJustLoggedIn,
} from './redux/actions/auth';
import { spinnerShow, spinnerHide } from './redux/actions/app';
import { account } from './appwrite';
/* eslint-disable import/no-relative-packages */
import {
  transformBookingNumberToEmail,
  transformLastNameToPassword,
} from '../appwrite-functions/src/utils/account-helper';
/* eslint-enable import/no-relative-packages */
import { getUserTimeline } from './redux/actions/timeline';

const loginCheck = () => {
  account.get()
    .then((user) => {
      console.log(`%c===> AUTH - LOGGED IN AS: ${user.email} | ${user.$id}`, 'color:white; background-color:seagreen; padding: 4px;');
      store.dispatch(spinnerShow());
      store.dispatch(userJustLoggedIn());
      store.dispatch(getUserTimeline());
    })
    .catch((e) => {
      console.log(e);
      store.dispatch(setLoggedOut());
      console.log('%c===> AUTH - NOT LOGGED IN!!!', 'color:white; background-color:tomato; padding: 4px;');
    });
};

/**
 * @param error
 * @returns {*|string}
 */
function mapAuthErrorMessageToNewCredentials(error) {
  if (error.code === 400 && error.type === 'general_argument_invalid') {
    return 'Ungültiger Buchungsnummer: Der Wert muss eine gültige Buchungsnummer sein.';
  }

  if (error.code === 401 && error.type === 'user_invalid_credentials') {
    return 'Ungültige Anmeldeinformationen. Bitte überprüfen Sie die Buchungsnummer und den Familiennamen.';
  }

  return error.message;
}

export const timelineLogin = (bookingNumber, lastName) => {
  const email = transformBookingNumberToEmail(bookingNumber);
  const password = transformLastNameToPassword(lastName);

  account.createEmailPasswordSession(email, password)
    .then(() => {
      loginCheck();
    }, (e) => {
      store.dispatch(addLoginErrorMessage(mapAuthErrorMessageToNewCredentials(e)));
      store.dispatch(spinnerHide());
    });
};

/**
 * Log out user using firebase authentication
 * The function logs out the currently logged in user
 * and takes no parameters
 */
export const timelineLogout = () => {
  account.deleteSession('current')
    .then(loginCheck);
};

loginCheck();
