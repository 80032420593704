import { LitElement, html } from 'lit';
import styles from './timeline-filters.css';

class TimelineFilters extends LitElement {
  render() {
    return html`
      <div class="timeline-filters__container">
        <button class="timeline-filters__filter timeline-filters__filter--momentary" @click="${this._handleFilterClick}" filter="close-all-groups">Close All</button>
        <button class="timeline-filters__filter timeline-filters__filter--momentary" @click="${this._handleFilterClick}" filter="open-all-groups">Open All</button>
        <button class="timeline-filters__filter timeline-filters__filter--momentary" @click="${this._handleFilterClick}" filter="close-all-past-groups">Close Past</button>
        <button class="timeline-filters__filter timeline-filters__filter--momentary" @click="${this._scrollToToday}">Today</button>
        <button class="timeline-filters__filter" @click="${this._handleFilterClick}">test</button>
      </div>
    `;
  }

  static get is() {
    return 'timeline-filters';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      openFilters: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.openFilters = false;
  }

  /**
   * When a filter button is clicked:
   *   - check if filter is momentary or state button. if state button, toggle active state
   *   - send correct filter type to store
   *   - close filter panel
   */
  _handleFilterClick(event) {
    if (!event.target.classList.contains('timeline-filters__filter--momentary')) {
      event.target.toggleAttribute('active');
    }

    if (event.target.hasAttribute('filter')) {
      this._sendFilterActions(event.target.getAttribute('filter'));
      this._closeFilters();
    }
  }

  _scrollToToday() {
    this._closeFilters();
    const newEvent = new CustomEvent('scroll-to-today', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(newEvent);
  }

  _sendFilterActions(filterName) {
    const newEvent = new CustomEvent(filterName, {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(newEvent);
  }

  _closeFilters() {
    const newEvent = new CustomEvent('close-filters', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(newEvent);
  }
}

window.customElements.define(TimelineFilters.is, TimelineFilters);
