import { LitElement, html } from 'lit';
import styles from './timeline-notification.css';

class TimelineNotification extends LitElement {
  render() {
    return this.renderNotification();
  }

  static get is() {
    return 'timeline-notification';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      notification: {
        type: Object,
      },
    };
  }

  constructor() {
    super();
    this.notification = {};
  }

  renderNotification() {
    return html`
      <div class="timeline-notification__header">
        ${this.notification.headerSpans.map((headerSpan) => {
    return html`<span class="timeline-notification__header-span">${headerSpan}</span>`;
  })}
      </div>
      <div class="timeline-notification__main">
        <div class="timeline-notification__icon-container">
            <img src="${this.notification.icon}" class="timeline-notification__icon" alt="Seabreeze voucher notification icon">
        </div>
        <div class="timeline-notification__content">
            <h4 class="timeline-notification__title">${this.notification.title}</h4>
            <p class="timeline-notification__text">${this.notification.text}</p>
        </div>
      </div>
      <div class="timeline-notification__actions">
        ${this.notification.actions.map((action) => {
    return html`
      <button class="timeline-notification__action" actionType="${action.actionType}" actionLink="${action.actionLink}" @click="${this._handleActionClick}">${action.actionText}</button>
    `;
  })}
      </div>
    `;
  }

  /**
   * This function handles clicks on the action buttons
   * At the moment we can differentiate between 'dismiss" or 'link' action buttons
   * Both actions should dismiss the notifications,
   * but the 'link' action should also open link on new tab
   */
  _handleActionClick(event) {
    // if action 'link', open link in new tab
    if (event.target.getAttribute('actionType') === 'link') {
      this._handleLinkClick(event.target.getAttribute('actionLink'));
    }
    // dismiss notification
    this._handleDismissClick(this.getAttribute('notificationid'));
  }

  /**
   * Open action link on new tab
   */
  // eslint-disable-next-line class-methods-use-this
  _handleLinkClick(actionLink) {
    window.open(actionLink, '_blank');
  }

  /**
   * When we click on a 'dismiss' button we should remove clicked the notifications
   */
  _handleDismissClick(notificationID) {
    const event = new CustomEvent('dismiss-notification', {
      bubbles: true,
      composed: true,
      detail: {
        notificationID,
      },
    });
    this.dispatchEvent(event);
  }
}

window.customElements.define(TimelineNotification.is, TimelineNotification);
