import {
  SET_CURRENT_PAGE,
  SPINNER_HIDE,
  SPINNER_SHOW,
  CLOSE_MENU,
  OPEN_MENU,
  OPEN_ACTIONBAR_PANEL,
  CLOSE_ACTIONBAR_PANEL,
  SET_MOBILE_FALSE,
  SET_MOBILE_TRUE,
} from '../constants/app';

export const setCurrentPage = (detail) => {
  return {
    type: SET_CURRENT_PAGE,
    payload: detail,
  };
};

export const spinnerShow = () => {
  return {
    type: SPINNER_SHOW,
  };
};

export const spinnerHide = () => {
  return {
    type: SPINNER_HIDE,
  };
};

export const openMenu = () => {
  return (dispatch) => {
    dispatch({
      type: OPEN_MENU,
    });
  };
};

export const closeMenu = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_MENU,
    });
  };
};

export const setMobileFalse = () => {
  return {
    type: SET_MOBILE_FALSE,
  };
};

export const setMobileTrue = () => {
  return {
    type: SET_MOBILE_TRUE,
  };
};

export const openActionbarPanel = () => {
  return (dispatch) => {
    dispatch({
      type: OPEN_ACTIONBAR_PANEL,
    });
  };
};

export const closeActionbarPanel = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ACTIONBAR_PANEL,
    });
  };
};
