export const SET_MAP_FEATURES = 'SET_MAP_FEATURES';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';

export const SET_TEASER_TO_HIGHLIGHT = 'SET_TEASER_TO_HIGHLIGHT';
export const UNSET_TEASER_TO_HIGHLIGHT = 'UNSET_TEASER_TO_HIGHLIGHT';

export const SET_OPEN_POPUP_ID = 'SET_OPEN_POPUP_ID';
export const UNSET_OPEN_POPUP_ID = 'UNSET_OPEN_POPUP_ID';

export const SHOW_MOBILE_MAP = 'SHOW_MOBILE_MAP';
export const HIDE_MOBILE_MAP = 'HIDE_MOBILE_MAP';
