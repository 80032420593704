import { LitElement, html } from 'lit';
import styles from './timeline-button.css';

class TimelineButton extends LitElement {
  static get is() {
    return 'timeline-button';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      buttonType: {
        type: String,
        attribute: 'button-type',
      },
    };
  }

  constructor() {
    super();

    this.buttonType = 'default';
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return html`
      <button class="button" type="${this.buttonType}">
        <slot></slot>
      </button>`;
  }
}

window.customElements.define(TimelineButton.is, TimelineButton);
