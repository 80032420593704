/**
 * @param {string} bookingNumber
 * @return {string}
 */
export const transformBookingNumberToEmail = bookingNumber => bookingNumber + '@sbt-voucher.de';

/**
 * @param {string} lastName
 * @return {string}
 */
export const transformLastNameToPassword = lastName => lastName.toLowerCase().padEnd(8, '$');

