import { LOAD_MENU, SET_MENU } from '../constants/destination-pages';
import { preFetchIframeContent } from '../../lib/utils';

export const loadMenu = (destinationString, packageString = null) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_MENU,
    });

    const entryRequestUrls = [
      'Allgemein_Oben',
      packageString,
      destinationString,
      'Allgemein_Unten',
    ].filter((code) => code !== null && code !== '')
      .map((code) => `${process.env.DESTINATION_MENU_API_ENDPOINT}${code}/`);

    const uniqueRequests = [...new Set(entryRequestUrls)].map(
      (entryURl) => fetch(entryURl)
        .then((response) => {
          if (response.status === 404) {
            throw new Error(`${entryURl}: returns 404`);
          }
          return response;
        })
        .then((response) => response.json())
        .catch((e) => console.error('[VOUCHER API]', e)),
    );

    let menu = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const uniqueRequest of uniqueRequests) {
      // eslint-disable-next-line no-await-in-loop
      const [data] = await Promise.all([uniqueRequest]);
      if (data !== undefined) {
        menu = [
          ...menu,
          ...data,
        ];
      }
    }
    dispatch({
      type: SET_MENU,
      payload: menu,
    });
    preFetchIframeContent(menu);
  };
};
