import { LitElement, html } from 'lit';
import styles from './timeline-actionbar.css';
import { ICON_NAME, ICON_SIZE } from '../../types/icon-types';

class TimelineActionbar extends LitElement {
  render() {
    return html`
        ${this._renderNormalActionbar()}
    `;
  }

  static get is() {
    return 'timeline-actionbar';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      mapView: { type: Boolean },
      actionbarPanelIsOpen: { type: Boolean },
      hasGuideLinks: {
        type: Boolean,
        reflect: true,
        attribute: 'has-guide-links',
      },
      menuSections: {
        type: Array,
      },
      tripGuideLinks: {
        type: Array,
      },
    };
  }

  constructor() {
    super();
    this.mapView = false;
    this.hasGuideLinks = false;
  }

  updated(changedProperties) {
    // todo: Once the page structure is defined we should find a better way to get the links
    if (changedProperties.has('menuSections')) {
      const tripguideSection = this.menuSections.find((section) => section.title === 'Reisehandbuch');
      this.tripGuideLinks = tripguideSection?.children;
      this.hasGuideLinks = this.tripGuideLinks?.length > 0;
    }
  }

  _renderNormalActionbar() {
    return html`
        <button
          class="timeline-actionbar__button timeline-actionbar__button--map"
          ?active="${this.mapView}"
          @click="${this._handleMapToggleClick}"
        >
          <timeline-icon
            class="timeline-actionbar__icon"
            icon-name="${ICON_NAME.MAP}"
            icon-size="${ICON_SIZE.MS}">
          </timeline-icon>
          <p class="timeline-actionbar__label">Map</p>
        </button>
        ${this.hasGuideLinks ? html`
          <button
            class="timeline-actionbar__button timeline-actionbar__button--guide"
            ?active="${this.actionbarPanelIsOpen}"
            @click="${this._handleGuideBookClick}"
          >
            <timeline-icon
              class="timeline-actionbar__icon"
              icon-name="${ICON_NAME.BOOK_OPEN}"
              icon-size="${ICON_SIZE.MS}">
            </timeline-icon>
            <p class="timeline-actionbar__label">Guide</p>
          </button>

        <timeline-actionbar-panel
          .tripGuideLinks="${this.tripGuideLinks}"
          .actionbarPanelIsOpen="${this.actionbarPanelIsOpen}"
          ?is-visible="${this.actionbarPanelIsOpen}"
        ></timeline-actionbar-panel>
        ` : ''}
    `;
  }

  /**
   * On map toggle click change the value of 'mapView' in the store
   * this will show / hide the map on mobile
   */
  _handleMapToggleClick() {
    this._saveScrollState();
    this.dispatchEvent(new CustomEvent(this.mapView ? 'hide-mobile-map' : 'show-mobile-map', {
      bubbles: true,
      composed: true,
    }));
    this._closeMenuAndFilters();
  }

  // eslint-disable-next-line class-methods-use-this
  _handleGuideBookClick() {
    this._saveScrollState();
    this.dispatchEvent(new CustomEvent(this.actionbarPanelIsOpen ? 'close-actionbar-panel' : 'open-actionbar-panel', {
      bubbles: true,
      composed: true,
    }));
    this._closeMenuAndFilters();
  }

  /**
   * Send actions to close the menu and filter panel
   */
  _closeMenuAndFilters() {
    this.dispatchEvent(new CustomEvent('close-menu', {
      bubbles: true,
      composed: true,
    }));
    this.dispatchEvent(new CustomEvent('close-filters', {
      bubbles: true,
      composed: true,
    }));
  }

  _saveScrollState() {
    if (!this.mapView) {
      this.dispatchEvent(new CustomEvent('set-scroll-position', {
        bubbles: true,
        composed: true,
        detail: {
          scrollPosition: (window.pageYOffset !== undefined) ? window.pageYOffset : 0,
        },
      }));
    } else {
      this.dispatchEvent(new CustomEvent('scroll-to-position', {
        bubbles: true,
        composed: true,
      }));
    }
  }
}

window.customElements.define(TimelineActionbar.is, TimelineActionbar);
