import { ReactiveElement } from 'lit';

const mapStyleLoadedMap = new WeakMap();

class TimelineMapGeojson extends ReactiveElement {
  static get is() { return 'timeline-map-geojson'; }

  static get properties() {
    return {
      sourceId: {
        type: String,
        attribute: 'source-id',
      },
      sourceData: {
        type: Object,
        attribute: 'source-data',
      },
      sourceUrl: {
        type: String,
        attribute: 'source-url',
      },
      maxZoom: {
        type: Number,
        attribute: 'max-zoom',
      },
      buffer: {
        type: Number,
      },
      tolerance: {
        type: Number,
      },
      cluster: {
        type: Boolean,
      },
      clusterRadius: {
        type: Number,
        attribute: 'cluster-radius',
      },
      clusterMaxZoom: {
        type: Number,
        attribute: 'cluster-max-zoom',
      },
    };
  }

  set map(map) {
    if (map !== this._map) {
      // eslint-disable-next-line no-unused-expressions
      this._map && this.removeSource();
      this._map = map;
      // eslint-disable-next-line no-unused-expressions
      map && this.initSource();
    }
  }

  get map() {
    return this._map;
  }

  set sourceData(sourceData) {
    // eslint-disable-next-line no-unused-expressions
    this.source && this.source.setData(sourceData);
    this.data = sourceData;
  }

  get sourceData() {
    return typeof this.data === 'object' ? this.data : null;
  }

  set sourceUrl(sourceUrl) {
    // eslint-disable-next-line no-unused-expressions
    this.source && this.source.setData(sourceUrl);
    this.data = sourceUrl;
  }

  get sourceUrl() {
    return typeof this.data === 'string' ? this.data : null;
  }

  get clusterConf() {
    if (this.cluster) {
      return {
        cluster: true,
        clusterRadius: this.clusterRadius,
        clusterMaxZoom: this.clusterMaxZoom || this.maxZoom - 1,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    this.maxZoom = 18;
    this.buffer = 128;
    this.tolerance = 0.375;
    this.clusterRadius = 50;
    this.clusterMaxZoom = null;
    this._map = null;
    this.source = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.dispatchEvent(new CustomEvent('timeline-map-element-attached', { detail: this, bubbles: true, composed: true }));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // eslint-disable-next-line no-unused-expressions
    this.map && this.removeSource();
  }

  // eslint-disable-next-line consistent-return
  initSource() {
    if (!mapStyleLoadedMap.has(this.map)) {
      mapStyleLoadedMap.set(this.map, this.map.isStyleLoaded());
    }

    if (!mapStyleLoadedMap.get(this.map)) {
      return this.map.once('load', () => {
        mapStyleLoadedMap.set(this.map, true);
        this.initSource();
      });
    }

    this.map.addSource(
      this.sourceId,
      {
        type: 'geojson',
        data: this.data,
        maxzoom: this.maxZoom,
        buffer: this.buffer,
        tolerance: this.tolerance,
        ...this.clusterConf,
      },
    );
    this.source = this.map.getSource(this.sourceId);
  }

  removeSource() {
    this.map.removeSource(this.sourceId);
  }
}

customElements.define(TimelineMapGeojson.is, TimelineMapGeojson);
