export const SET_TIMELINE = 'SET_TIMELINE';
export const SET_TIMELINE_ENTRIES_REFERENCE_DATA = 'SET_TIMELINE_ENTRIES_REFERENCE_DATA';

export const CLOSE_ALL_GROUPS = 'CLOSE_ALL_GROUPS';
export const OPEN_CURRENT_GROUPS = 'OPEN_CURRENT_GROUPS';
export const CLOSE_GROUP = 'CLOSE_GROUP';
export const OPEN_ALL_GROUPS = 'OPEN_ALL_GROUPS';
export const OPEN_GROUP = 'OPEN_GROUP';

export const CHECK_TODO = 'CHECK_TODO';
export const UNCHECK_TODO = 'UNCHECK_TODO';
