import { LitElement, html } from 'lit';
import mapboxgl from 'mapbox-gl';
import styles from './timeline-map.css';

class TimelineMap extends LitElement {
  static get is() { return 'timeline-map'; }

  static get styles() {
    return styles;
  }

  render() {
    return html`
      <div id="map"></div>
      <slot @timeline-map-element-attached="${this.updateMapElements}" @slotchange="${this.updateMapElements}"></slot>
    `;
  }

  static get properties() {
    return {
      mapView: {
        type: Boolean,
      },
      mapboxStyles: {
        type: String,
      },
      interactive: {
        type: Boolean,
      },
      noNavigation: {
        type: Boolean,
        attribute: 'no-navigation',
      },
      accessToken: {
        type: String,
        attribute: 'access-token',
      },
      center: {
        type: Array,
      },
      zoom: {
        type: Number,
      },
      maxZoom: {
        type: Number,
      },
      minZoom: {
        type: Number,
      },
      pitch: {
        type: Number,
      },
      bearing: {
        type: Number,
      },
      mapStyle: {
        type: String,
        attribute: 'map-style',
      },
      loaded: {
        type: Boolean,
      },
      mapBounds: {
        type: Array,
      },
      zoomOnScroll: {
        type: Boolean,
      },
    };
  }

  set mapStyle(mapStyle) {
    // eslint-disable-next-line no-unused-expressions
    this.map && this.map.setStyle(mapStyle);
    this.initialMapOptions.style = mapStyle;
  }

  get mapStyle() {
    return this.map ? this.map.getStyle() : this.initialMapOptions.style;
  }

  set center(lngLatLike) {
    // eslint-disable-next-line no-unused-expressions
    this.map && this.map.setCenter(lngLatLike);
    this.initialMapOptions.center = lngLatLike;
  }

  get center() {
    return this.map ? this.map.getCenter() : this.initialMapOptions.center;
  }

  set zoom(zoom) {
    // eslint-disable-next-line no-unused-expressions
    this.map && this.map.setZoom(zoom);
    this.initialMapOptions.zoom = zoom;
  }

  get zoom() {
    return this.map ? this.map.getZoom() : this.initialMapOptions.zoom;
  }

  set pitch(pitch) {
    // eslint-disable-next-line no-unused-expressions
    this.map && this.map.setPitch(pitch);
    this.initialMapOptions.pitch = pitch;
  }

  get pitch() {
    return this.map ? this.map.getPitch() : this.initialMapOptions.pitch;
  }

  set bearing(bearing) {
    // eslint-disable-next-line no-unused-expressions
    this.map && this.map.setBearing(bearing);
    this.initialMapOptions.bearing = bearing;
  }

  get bearing() {
    return this.map ? this.map.getBearing() : this.initialMapOptions.bearing;
  }

  // eslint-disable-next-line consistent-return
  set noNavigation(noNavigation) {
    if (!this.map) {
      // eslint-disable-next-line no-setter-return, no-return-assign
      return this.initialControls = { ...this.initialControls, noNavigation: !!noNavigation };
    }
    if (!noNavigation) {
      // eslint-disable-next-line no-unused-expressions
      !this._navigationControl && (this._navigationControl = new mapboxgl.NavigationControl());
      this.map.addControl(this._navigationControl);
    } else if (noNavigation && this._navigationControl) {
      this.map.removeControl(this._navigationControl);
    }
  }

  get noNavigation() {
    if (!this.map) {
      return this.initialControls.noNavigation;
    }
    return !!this._navigationControl;
  }

  set interactive(interactive) {
    this.initialMapOptions.interactive = !!interactive;
  }

  get interactive() {
    return this.initialMapOptions.interactive;
  }

  set maxZoom(maxZoom) {
    // eslint-disable-next-line no-unused-expressions
    this.map && this.map.setMaxZoom(maxZoom);
    this.initialMapOptions.maxZoom = maxZoom;
  }

  get maxZoom() {
    return this.map ? this.map.getMaxZoom() : this.initialMapOptions.maxZoom;
  }

  set minZoom(minZoom) {
    // eslint-disable-next-line no-unused-expressions
    this.map && this.map.setMinZoom(minZoom);
    this.initialMapOptions.minZoom = minZoom;
  }

  get minZoom() {
    return this.map ? this.map.getMinZoom() : this.initialMapOptions.minZoom;
  }

  constructor(props) {
    super(props);
    this.mapView = false;
    this.accessToken = 'pk.eyJ1Ijoic2VhYnJlZXplIiwiYSI6ImNqdWUxMWJtMDA4ZTEzeW56Mmc5M2tyOWkifQ.De9OBbERLFD-k5QyQGG5IA';
    this.initialMapOptions = {
      style: 'mapbox://styles/seabreeze/cjy5kcrbm0s5o1cof74rol94x?optimize=true',
      minZoom: 0,
      maxZoom: 20,
      pitch: 0,
      bearing: 0,
      attributionControl: false,
    };
    this.initialControls = {
      noNavigation: false,
    };
    this.loaded = window.mapboxgl && true;
    this.targets = [];
    this.markers = [];
    this.zoomOnScroll = false;
    this.mapBounds = '';
  }

  connectedCallback() {
    super.connectedCallback();
    window.mapboxgl = mapboxgl;
    this.loaded = true;
  }

  updated(changedProperties) {
    if (changedProperties.has('loaded') && this.loaded && !this.map) {
      this.createMap();
    }

    if (changedProperties.has('mapBounds')) {
      this.fitMapToBounds(this.mapBounds);
    }

    if (changedProperties.has('zoomOnScroll')) {
      this._setZoomOnScroll();
    }
  }

  createMap() {
    mapboxgl.accessToken = this.accessToken;

    this.map = new mapboxgl.Map({
      container: this.shadowRoot.querySelector('#map'),
      ...this.initialMapOptions,
    });
    this.map.addControl(new mapboxgl.AttributionControl({
      compact: true,
    }));
    this.map.setPadding({
      top: 50, bottom: 50, left: 50, right: 50,
    });

    Object.keys(this.initialControls).forEach((controlName) => {
      this[controlName] = this.initialControls[controlName];
    });

    this.map.on('load', (e) => { this.dispatchEvent(new CustomEvent('timeline-map-loaded', { detail: e })); });
    this.map.on('error', (e) => {
      this.dispatchEvent(new CustomEvent('timeline-map-error', { detail: e }));
      console.error(e);
    });

    this.updateMapElements();
    this.fitMapToBounds();
    this.dispatchEvent(new CustomEvent('timeline-map-ready', { detail: this.map }));
  }

  _setZoomOnScroll() {
    // eslint-disable-next-line no-unused-expressions
    this.zoomOnScroll ? this.map.scrollZoom.enable() : this.map.scrollZoom.disable();
  }

  // eslint-disable-next-line consistent-return
  fitMapToBounds() {
    // Fit the set area (defined in BE) on the screen, no matter the screen size
    // Coordinates order must be: West, South, East, North
    if (this.mapBounds) {
      return this.map.fitBounds(this.mapBounds);
    }
  }

  updateMapElements() {
    const nodes = this.shadowRoot.querySelector('slot').assignedNodes({ flatten: true });
    const mapElements = nodes.filter((n) => n.nodeName.slice(0, 12) === 'TIMELINE-MAP');
    this.markers = mapElements.filter((n) => n.nodeName === 'TIMELINE-MAP-MARKER');

    if (this.map) {
      // eslint-disable-next-line no-param-reassign
      mapElements.forEach((n) => { n.map = this.map; });
    }
  }
}

customElements.define(TimelineMap.is, TimelineMap);
