import { LitElement, html } from 'lit';
import styles from './timeline-separator.css';

class TimelineSeparator extends LitElement {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return html`
      <hr class="timeline-separator__line">
    `;
  }

  static get is() {
    return 'timeline-separator';
  }

  static get styles() {
    return styles;
  }

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }
}

window.customElements.define(TimelineSeparator.is, TimelineSeparator);
