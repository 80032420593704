import { CLOSE_MODAL, OPEN_MODAL } from '../constants/modal';

export const openModal = (detail, pushState = true) => {
  if (pushState) {
    window.history.pushState(detail, '');
  }
  return {
    type: OPEN_MODAL,
    payload: detail,
  };
};

export const closeModal = (pushState = true) => {
  if (pushState) {
    window.history.pushState({}, '');
  }
  return {
    type: CLOSE_MODAL,
  };
};
