import { LitElement, html } from 'lit';
import styles from './timeline-map-wrapper.css';

class TimelineMapWrapper extends LitElement {
  static get is() { return 'timeline-map-wrapper'; }

  static get styles() {
    return styles;
  }

  render() {
    return html`
      <timeline-map .mapBounds="${this.mapBounds}" .zoomOnScroll="${this.zoomOnScroll}" @timeline-map-ready="${this.registerListeners}">
        <!-- POINTS -->
        ${this.featureCollectionPoints ? html`

          <timeline-map-layer
            layer-id="mapFeatures_points"
            source="mapPointFeatures"
            type="circle"
            paint='{
              "circle-color": [
                  "match",
                  ["get", "entryType"],
                  "train", "#9ad9de",
                  "car", "#e0a555",
                  "flight", "#6fd49a",
                  "hotel", "#a9a0d8",
                  "nature", "#6fd49a",
                  "restaurant", "#e0d555",
                  "page", "#015b86",
                  "#a8c640"
              ],
              "circle-radius": 6,
              "circle-stroke-width": 1,
              "circle-stroke-color": "#ffffff"
            }'></timeline-map-layer>

          <timeline-map-layer
            layer-id="clusters"
            source="mapPointFeatures"
            type="circle"
            filter='["has", "point_count"]'
            paint='{
              "circle-color": [
                "step",
                ["get", "point_count"],
                "#a8c640", 3,
                "#a8c640", 5,
                "#a8c640", 10,
                "#a8c640", 25,
                "#a8c640"
              ],
              "circle-radius": [
                "step",
                ["get", "point_count"],
                12, 3,
                16, 5,
                20, 10,
                25, 25,
                30
              ],
              "circle-stroke-width": 2,
              "circle-stroke-color": "#ffffff"
            }'></timeline-map-layer>

          <timeline-map-layer
            layer-id="cluster-count"
            source="mapPointFeatures"
            type="symbol"
            filter='["has", "point_count"]'
            layout='{
              "text-field": "{point_count_abbreviated}",
              "text-size": 16
            }'
            paint='{
              "text-color": "#ffffff"
            }'></timeline-map-layer>

            <timeline-map-geojson
              id="entriesSourcePoints"
              source-id="mapPointFeatures"
              .sourceData="${this.featureCollectionPoints}"
              cluster
              cluster-radius="50"
              cluster-max-zoom="8"></timeline-map-geojson>
        ` : ''}

        <!-- POLYGONS -->
        ${this.featureCollectionPolygons ? html`

          <timeline-map-layer
            layer-id="mapFeatures_polygons"
            source="mapPolygonFeatures"
            type="fill"
            min-zoom="8"
            paint='{
              "fill-color": [
                "match",
                ["get", "entryType"],
                "train", "#9ad9de",
                "car", "#e0a555",
                "flight", "#6fd49a",
                "hotel", "#a9a0d8",
                "nature", "#6fd49a",
                "restaurant", "#e0d555",
                "#015b86"
              ],
              "fill-opacity": 0.25
            }'></timeline-map-layer>

            <timeline-map-geojson
              id="entriesSourcePolygons"
              source-id="mapPolygonFeatures"
              .sourceData="${this.featureCollectionPolygons}"></timeline-map-geojson>
        ` : ''}

        <!-- LINES -->
        ${this.featureCollectionLines ? html`

          <timeline-map-layer
            layer-id="mapFeatures_lines"
            source="mapLineFeatures"
            type="line"
            min-zoom="8"
            layout='{
              "line-join": "round",
              "line-cap": "round"
            }'
            paint='{
              "line-color": [
                "match",
                ["get", "entryType"],
                "train", "#9ad9de",
                "car", "#e0a555",
                "flight", "#6fd49a",
                "hotel", "#a9a0d8",
                "nature", "#6fd49a",
                "restaurant", "#e0d555",
                "#015b86"
              ],
              "line-opacity": 0.5,
              "line-width": {
                "stops": [
                  [ 0, 0],
                  [ 10, 2],
                  [ 12, 4],
                  [ 14, 8],
                  [ 16, 30]
                ]
              }
            }'></timeline-map-layer>

            <timeline-map-geojson
              id="entriesSourceLines"
              source-id="mapLineFeatures"
              .sourceData="${this.featureCollectionLines}"></timeline-map-geojson>
        ` : ''}

        ${this.popup ? html`
          <timeline-map-popup
            .coordinates="${this.popup.coordinates}"
            @timeline-map-popup-closed="${this.onPopupClose}"
            close-button>${this.renderPopupContent()}</timeline-map-popup>
         ` : ''}

      </timeline-map>

      ${this.loading ? html`
        <div class="timeline-map__loading">
          <p class="timeline-map__loading-text">
            Loading...
          </p>
        </div>
      ` : ''}
    `;
  }

  static get properties() {
    return {
      mapBounds: {
        type: Array,
      },
      featureCollection: {
        type: Object,
      },
      featureCollectionPoints: {
        type: Object,
      },
      featureCollectionPolygons: {
        type: Object,
      },
      featureCollectionLines: {
        type: Object,
      },
      featureToHighlight: {
        type: String,
      },
      openPopupId: {
        type: String,
      },
      map: {
        type: Object,
      },
      popup: {
        type: Object,
      },
      popupContent: {
        type: Object,
      },
      zoomOnScroll: {
        type: Boolean,
      },
      loading: {
        type: Boolean,
      },
    };
  }

  constructor(props) {
    super(props);
    this.loading = true;
    this.featureCollection = {};
    this.featureCollectionPoints = {
      type: 'FeatureCollection',
      features: [],
    };
    this.featureCollectionPolygons = {
      type: 'FeatureCollection',
      features: [],
    };
    this.featureCollectionLines = {
      type: 'FeatureCollection',
      features: [],
    };
    this.featureToHighlight = '';
    this.zoomOnScroll = false;

    this.markerHighlightColor = '#a8c640';
    this.featureToHighlight = null;
    this.openPopupId = null;
    this.defaultMarkerColors = ['match',
      ['get', 'entryType'],
      'train', '#9ad9de',
      'car', '#e0a555',
      'flight', '#6fd49a',
      'hotel', '#a9a0d8',
      'nature', '#6fd49a',
      'restaurant', '#e0d555',
      'page', '#015b86',
      '#a8c640']; // the last on is the default color

    this.onFeatureHoverEnter = this.onFeatureHoverEnter.bind(this);
    this.onFeatureHoverLeave = this.onFeatureHoverLeave.bind(this);
    this.onFeatureClick = this.onFeatureClick.bind(this);
    this.onNonFeatureClick = this.onNonFeatureClick.bind(this);
    this._centerPopup = this._centerPopup.bind(this);
    this.onClusterClick = this.onClusterClick.bind(this);
    this.addIntersectionObserver = this.addIntersectionObserver.bind(this);
  }

  updated(changedProperties) {
    if (changedProperties.has('featureCollection')) {
      this._separateFeatureTypes();
    }

    if (this.map && changedProperties.has('openPopupId')) {
      // eslint-disable-next-line max-len
      if (this.openPopupId === null || !this.featureCollection.features.find((feature) => feature.properties.identifier === this.openPopupId)) {
        this.popup = null;
      } else {
        this.showMapPopup();
      }
    }
  }

  registerListeners(event) {
    this.map = event.detail;

    this.map.on('click', 'clusters', this.onClusterClick);
    this.map.on('mouseenter', 'clusters', () => { this.map.getCanvas().style.cursor = 'pointer'; });
    this.map.on('mouseleave', 'clusters', () => { this.map.getCanvas().style.cursor = ''; });

    // hover enter map point feature
    this.map.on('mouseover', 'mapFeatures_points', this.onFeatureHoverEnter);
    // hover leave map point feature
    this.map.on('mouseleave', 'mapFeatures_points', this.onFeatureHoverLeave);
    // click on map point feature
    this.map.on('click', 'mapFeatures_points', this.onFeatureClick);

    // hover enter map polygon feature
    this.map.on('mouseover', 'mapFeatures_polygons', this.onFeatureHoverEnter);
    // hover leave map polygon feature
    this.map.on('mouseleave', 'mapFeatures_polygons', this.onFeatureHoverLeave);
    // click on map polygon feature
    this.map.on('click', 'mapFeatures_polygons', this.onFeatureClick);

    // hover enter map lines feature
    this.map.on('mouseover', 'mapFeatures_lines', this.onFeatureHoverEnter);
    // hover leave map lines feature
    this.map.on('mouseleave', 'mapFeatures_lines', this.onFeatureHoverLeave);
    // click on map lines feature
    this.map.on('click', 'mapFeatures_lines', this.onFeatureClick);

    // check for click outside a feature ("hack" around timing problem,
    // to open a new popup if one already exists)
    this.map.on('click', this.onNonFeatureClick);

    // remove the "Loading..." sign when map completely loaded for the first time
    this.map.once('idle', () => { this.loading = false; });

    // add intersection observer to know when the map is visible
    this.addIntersectionObserver();
  }

  // Add an intersection observer to allow us to resize the map each time it comes into view.
  // The problem was on mobile phones:
  //    - When the scroll bar was visible at the moment of changing into map view
  //    the map size would be calculated as if the screen was too small
  //    (to accommodate for the scroll bar), leaving an empty space
  addIntersectionObserver() {
    const observer = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting > 0) {
        this.map.resize();
      }
    });
    observer.observe(this);
  }

  _centerPopup(selectedFeature) {
    /* need to wait until next frame so the element has been rendered */
    setTimeout(() => {
      const popupLocation = this.map.project(selectedFeature.geometry.coordinates);
      const popupHeight = this.shadowRoot.querySelector('timeline-map-popup').clientHeight;
      popupLocation.y -= (popupHeight / 2);
      this.map.panTo(this.map.unproject(popupLocation), { animate: true });
    });
  }

  checkIfEntryHasFeatures(identifier) {
    let featureExists = false;
    const features = this.map.queryRenderedFeatures();
    features.forEach((feature) => {
      if (feature.properties.identifier === identifier) {
        featureExists = true;
      }
    });
    return featureExists;
  }

  static extractCoordinates(feature, lngLat) {
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being feature to.
    const coordinates = feature.geometry.coordinates.slice();
    while (Math.abs(lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += lngLat.lng > coordinates[0] ? 360 : -360;
    }
    return coordinates;
  }

  onClusterClick(event) {
    const selectedCluster = this.map.queryRenderedFeatures(event.point, { layers: ['clusters'] })[0] || null;
    if (!selectedCluster) {
      return;
    }

    const coordinates = TimelineMapWrapper.extractCoordinates(selectedCluster, event.lngLat);

    this.map.getSource('mapPointFeatures').getClusterExpansionZoom(selectedCluster.id, (error, expansionZoom) => {
      // eslint-disable-next-line no-unused-expressions
      !error && this.map.zoomTo(expansionZoom, { center: coordinates }, { clusterExpansion: true });
    });
  }

  onFeatureClick(event) {
    const selectedFeature = this.map.queryRenderedFeatures(event.point)[0];

    // check if it is not a cluster
    if (!selectedFeature.properties.cluster) {
      setTimeout(() => {
        this.dispatchEvent(new CustomEvent('set-open-popup-id', {
          bubbles: true,
          composed: true,
          detail: {
            teaserID: selectedFeature.properties.identifier,
          },
        }));
      });
    } else if (selectedFeature.properties.cluster) {
      // eslint-disable-next-line no-useless-return
    } else {
      console.log('This entry has no popup');
    }
  }

  onFeatureHoverEnter(event) {
    if (!event.features[0].properties.cluster) {
      this.map.getCanvas().style.cursor = 'pointer';
      this.dispatchEvent(new CustomEvent('set-teaser-to-highlight', {
        bubbles: true,
        composed: true,
        detail: { teaserID: event.features[0].properties.identifier },
      }));
    }
  }

  onFeatureHoverLeave() {
    this.map.getCanvas().style.cursor = 'grab';
    this.dispatchEvent(new CustomEvent('unset-teaser-to-highlight', { bubbles: true, composed: true }));
  }

  onNonFeatureClick() {
    if (this.openPopupId) {
      this.popup = null;
    }
  }

  onPopupClose() {
    this.popup = null;
    this.dispatchEvent(new CustomEvent('unset-open-popup-id', {
      bubbles: true,
      composed: true,
    }));
  }

  renderPopupContent() {
    const popupImage = this.popup.item.image;
    const popupTags = []; // JSON.parse(this.popup.item.tags);
    const popupFacilityType = this.popup.item.facilityType;
    const popupTitle = this.popup.item.name;
    const popupLocations = []; // JSON.parse(this.popup.item.locations);
    const popupDescription = this.popup.item.slogan;
    const popupButtonAmount = null; // this.popup.item.buttonAmount;
    const popupButtonText = null; // this.popup.item.buttonText;
    const popupButtonLink = null; // this.popup.item.buttonLink;

    return html`
      <div class="timeline-popup__image-section">

        ${popupImage ? html`
          <div class="timeline-popup__image-container">
            <img src="${popupImage}" alt="" class="timeline-popup__image">
          </div>
        ` : ''}

          ${popupTags ? html`
            <div class="timeline-popup__tags">
                ${popupTags.map((popupTag) => html`
                  <div class="timeline-popup__tag">${popupTag}</div>
                `)}
            </div>
          ` : ''}

          ${popupFacilityType ? html`
            <div class="timeline-popup__facility-type">${popupFacilityType}</div>
          ` : ''}

      </div>
      <div class="timeline-popup__infos">

        ${popupTitle ? html`
            <h3 class="timeline-popup__title">${popupTitle}</h3>
        ` : ''}

        ${popupLocations ? html`
          <div class="timeline-popup__locations-container">
            ${popupLocations.map((popupLocation) => html`
              <p class="timeline-popup__location">${popupLocation}</p>
            `)}
          </div>
        ` : ''}

        ${popupDescription ? html`
          <p class="timeline-popup__description">${popupDescription}</p>
        ` : ''}
      </div>

      ${popupButtonText && popupButtonLink ? html`
        <div class="timeline-popup__button-container">
            <button class="timeline-popup__button" buttonLink="${popupButtonLink}" @click="${this._handlePopupButtonClick}">${popupButtonText} <span class="timeline-popup__button-price">${popupButtonAmount}</span></button>
        </div>
      ` : ''}
  </div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _handlePopupButtonClick(event) {
    event.preventDefault();
    const buttonLink = event.target.getAttribute('buttonLink');
    console.log('POPUP BUTTON CLICK', '\n', 'What should happen when we click on the popup buttons? Go to an external page? Do we need the button?', '\n', `On this button the link was: ${buttonLink}`);
  }

  _separateFeatureTypes() {
    const separatedFeaturesPoints = [];
    const separatedFeaturesPolygons = [];
    const separatedFeaturesLines = [];

    const features = this.featureCollection?.features ?? [];

    features.forEach((feature) => {
      if (feature.geometry.type === 'Point' || feature.geometry.type === 'MultiPoint') {
        separatedFeaturesPoints.push(feature);
      } else if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
        separatedFeaturesPolygons.push(feature);
      } else if (feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString') {
        separatedFeaturesLines.push(feature);
      } else {
        console.log('It looks like one of your features is of an unknown type', feature);
      }
    });

    this.featureCollectionPoints.features = separatedFeaturesPoints;
    this.featureCollectionPolygons.features = separatedFeaturesPolygons;
    this.featureCollectionLines.features = separatedFeaturesLines;
  }

  showMapPopup() {
    // eslint-disable-next-line max-len
    const selectedFeature = this.featureCollection.features.find((feature) => feature.properties.identifier === this.openPopupId);
    this.popup = {
      coordinates: selectedFeature.geometry.coordinates,
      item: selectedFeature.properties,
    };

    this._centerPopup(selectedFeature);
  }
}

customElements.define(TimelineMapWrapper.is, TimelineMapWrapper);
