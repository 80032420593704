import { LitElement, html } from 'lit';
import styles from './timeline-notifications.css';
import '../timeline-notification/timeline-notification';

class TimelineNotifications extends LitElement {
  // eslint-disable-next-line consistent-return
  render() {
    if (this.notifications) {
      return this.renderContent();
    }
  }

  static get is() {
    return 'timeline-notifications';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      notifications: { type: Array },
      notificationsLoading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.notifications = [];
    this.notificationsLoading = false;
  }

  /**
   * Render the element's content
   * If there are notifications: render the notifications
   * Otherwise show a message
   */
  renderContent() {
    return html`
      ${this.notifications.length > 0 ? this.renderNotifications() : this.renderNoNotificationsMessage()}
    
      ${this.notificationsLoading
    ? html`
        <div class="timeline-notifications__spinner-container">
          <timeline-spinner></timeline-spinner>  
        </div>
      `
    : ''
}
    `;
  }

  renderNotifications() {
    return this.notifications.map((notification) => html`
        <timeline-notification .notification="${notification}" notificationID="${notification.notificationID}" role="alert"></timeline-notification>
    `);
  }

  // eslint-disable-next-line class-methods-use-this
  renderNoNotificationsMessage() {
    return html`<p class="timeline-notifications__no-notifications">You have no new notifications...</p>`;
  }
}

window.customElements.define(TimelineNotifications.is, TimelineNotifications);
