import { databases } from '../../appwrite';
import { store } from '../../store';
import { addLoginErrorMessage } from './auth';
import {
  SET_TIMELINE,
  SET_TIMELINE_ENTRIES_REFERENCE_DATA,
  OPEN_CURRENT_GROUPS,
  CLOSE_GROUP, OPEN_ALL_GROUPS, OPEN_GROUP, CHECK_TODO, UNCHECK_TODO,
} from '../constants/timeline';
import { initMap } from './map';
import { spinnerHide } from './app';
import { loadMenu } from './destination-pages';
import { MODAL_ENTRY_TYPES } from '../../types/modal-entry-types';

export const closeGroup = (detail) => {
  return {
    type: CLOSE_GROUP,
    payload: detail,
  };
};

export const openCurrentGroups = () => {
  return {
    type: OPEN_CURRENT_GROUPS,
  };
};

export const openAllGroups = () => {
  return {
    type: OPEN_ALL_GROUPS,
  };
};

export const openGroup = (detail) => {
  return {
    type: OPEN_GROUP,
    payload: detail,
  };
};

const addDateToGroups = (timeline) => {
  return {
    ...timeline,
    groups: timeline.groups.map((group) => {
      return {
        ...group,
        date: group.group_id ? group.group_id.replaceAll('_', '-') : null,
      };
    }),
  };
};

export const setTimeLineEntriesReferenceData = (timeline) => {
  return async (dispatch) => {
    const entryRequestUrls = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const refAttribute of ['ref', 'ref_2']) {
      entryRequestUrls.push(...timeline.entries.filter((entry) => entry[refAttribute])
        .map((entry) => {
          if (entry.type === MODAL_ENTRY_TYPES.HOTEL) {
            return `${process.env.HOTEL_DATA_API_ENDPOINT + entry[refAttribute]}/`;
          } if (entry.type === MODAL_ENTRY_TYPES.CAR) {
            return `${process.env.LOCALBUSINESS_DATA_API_ENDPOINT + entry[refAttribute]}/`;
          } if (MODAL_ENTRY_TYPES.getAdventureTypes().includes(entry.type)) {
            return `${process.env.ADVENTURE_DATA_API_ENDPOINT + entry[refAttribute]}/`;
          }
          return undefined;
        }).filter((url) => url !== undefined));
    }

    const uniqueRequests = [...new Set(entryRequestUrls)].map(
      (entryURl) => fetch(entryURl)
        .then((response) => {
          if (response.status === 404) {
            throw new Error(`${entryURl}: returns 404`);
          }
          return response;
        })
        .then((response) => response.json())
        .catch((e) => console.error('[VOUCHER API]', e)),
    );

    const resultObject = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const uniqueRequest of uniqueRequests) {
      // eslint-disable-next-line no-await-in-loop
      const [data] = await Promise.all([uniqueRequest]);
      if (data !== undefined) {
        resultObject[data.identifier] = data;
      }
    }

    dispatch({
      type: SET_TIMELINE_ENTRIES_REFERENCE_DATA,
      payload: resultObject,
    });

    dispatch(initMap(timeline, resultObject));
  };
};

export const getUserTimeline = () => {
  return (dispatch) => {
    databases.listDocuments('default', 'timeline').then((result) => {
      if (result.total >= 1) {
        const timeline = addDateToGroups(result.documents[0]);
        store.dispatch({
          type: SET_TIMELINE,
          payload: timeline,
        });

        dispatch(setTimeLineEntriesReferenceData(timeline));
        // todo: replace by real destination code (when delivered by Johannes)
        dispatch(loadMenu(timeline.destination, timeline.ref));
        dispatch(openCurrentGroups());
        dispatch(spinnerHide());
      }
    }).catch((error) => {
      console.log('checkUserPermissions error', error);
      store.dispatch(addLoginErrorMessage(
        "It looks like you don't have the necessary permissions to access this timeline.",
      ));
    });
  };
};

export const toggleTodo = (detail, todoDone) => {
  const data = {
    todoDone,
  };
  return (dispatch) => {
    dispatch({
      type: todoDone ? CHECK_TODO : UNCHECK_TODO,
      payload: detail,
    });

    databases.updateDocument(
      detail.$databaseId,
      detail.$collectionId,
      detail.$id,
      data,
    );
  };
};
