import bbox from '@turf/bbox';
import {
  SET_MAP_BOUNDS,
  SET_MAP_FEATURES,
  SET_TEASER_TO_HIGHLIGHT, UNSET_TEASER_TO_HIGHLIGHT,
  SET_OPEN_POPUP_ID,
  UNSET_OPEN_POPUP_ID,
  HIDE_MOBILE_MAP, SHOW_MOBILE_MAP,
} from '../constants/map';

export const initMap = (timeline, features) => {
  return (dispatch) => {
    const mapFeatures = Object.values(features).map((data) => {
      const entryDataSet = timeline.entries.find(
        (entry) => entry?.ref === data.identifier,
      );

      return {
        id: data.identifier,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(data.longitude), parseFloat(data.latitude)],
        },
        properties: {
          ...data,
          entryType: entryDataSet?.type,
        },
      };
    });
    const geoJson = {
      type: 'FeatureCollection',
      features: mapFeatures,
    };

    dispatch({
      type: SET_MAP_FEATURES,
      payload: geoJson,
    });

    if (mapFeatures.length > 0) {
      const boundingBox = bbox(geoJson);
      dispatch({
        type: SET_MAP_BOUNDS,
        payload: [
          boundingBox.slice(0, 2),
          boundingBox.slice(2, 4),
        ],
      });
    }
  };
};

export const setTeaserToHighlight = (detail) => {
  return {
    type: SET_TEASER_TO_HIGHLIGHT,
    payload: detail,
  };
};

export const unsetTeaserToHighlight = () => {
  return {
    type: UNSET_TEASER_TO_HIGHLIGHT,
  };
};

export const setOpenPopupID = (teaserID) => {
  return {
    type: SET_OPEN_POPUP_ID,
    payload: teaserID,
  };
};

export const unsetOpenPopupID = () => {
  return {
    type: UNSET_OPEN_POPUP_ID,
  };
};

export const showMobileMap = () => {
  return {
    type: SHOW_MOBILE_MAP,
  };
};

export const hideMobileMap = () => {
  return {
    type: HIDE_MOBILE_MAP,
  };
};
