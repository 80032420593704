import { LOAD_MENU, SET_MENU } from '../constants/destination-pages';

const initialState = {
  loading: false,
  pages: [],
};

export default function destinationPagesReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_MENU:
      return {
        ...state,
        loading: true,
      };
    case SET_MENU:
      return {
        ...state,
        loading: false,
        pages: action.payload,
      };
    default:
      return state;
  }
}
