import { html, LitElement } from 'lit';
import { iframeResizer } from 'iframe-resizer';

class TimelineIframeResizer extends LitElement {
  static get is() {
    return 'timeline-iframe-resizer';
  }

  render() {
    return html`
      <slot @slotchange="${this.handleSlotChange}"></slot>`;
  }

  /**
   * @param {Event} event
   */
  // eslint-disable-next-line class-methods-use-this
  handleSlotChange(event) {
    event.target.assignedNodes({ flatten: true })
      .filter((node) => node instanceof HTMLIFrameElement)
      .forEach((iframe) => {
        let iframeOptions = {};
        try {
          iframeOptions = JSON.parse(iframe.getAttribute('timeline-iframe'))
            || {};
        } catch (e) {
          // eslint-disable-next-line no-console
          (console.error || console.log).call(console, e.stack || e);
        }
        iframeResizer(iframeOptions, iframe);
      });
  }
}

customElements.define(TimelineIframeResizer.is, TimelineIframeResizer);
