import { LitElement, html } from 'lit';

// eslint-disable-next-line import/extensions
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import styles from './timeline-modal.css';
import {
  MODAL_ASPECT_DESCRIPTION,
  MODAL_ASPECT_INFOS, MODAL_ASPECT_PAGE,
} from '../../types/modal-aspect';
import { MODAL_ENTRY_TYPES } from '../../types/modal-entry-types';
import '../timeline-iframe-resizer/timeline-iframe-resizer';
import { ICON_NAME, ICON_SIZE } from '../../types/icon-types';

class TimelineModal extends LitElement {
  static get is() {
    return 'timeline-modal';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      entry: {
        type: Object,
      },
      feature: {
        type: Object,
      },
      aspect: {
        type: String,
      },
      loading: {
        type: Boolean,
      },
      additionalFeatures: {
        type: Array,
      },
    };
  }

  constructor() {
    super();
    this.entry = {};
    this.feature = {};
    this.aspect = '';
    this.loading = false;
    this.additionalFeatures = [];
  }

  static mapEntryTypeToTitle(type) {
    if (type === MODAL_ENTRY_TYPES.CAR) {
      return 'Ihre Autovermietung';
    } if (
      type === MODAL_ENTRY_TYPES.HOTEL
      || MODAL_ENTRY_TYPES.getAdventureTypes().includes(type)
    ) {
      return 'Ihre Buchung';
    }

    return 'Informationen';
  }

  render() {
    return html`
      ${this._renderHeader()}
      ${this._renderContent()}
    `;
  }

  _renderHeader() {
    const title = this.entry?.headline ?? this.entry?.title;
    const image = this.feature?.image ?? this.entry?.image;
    return html`
      <header class="timeline-modal__header" entry-type="${this.entry?.type ? this.entry.type : 'default'}">
        ${image ? html`<img class="timeline-modal__image" src="${image}"/>` : ''}
        <h3 class="timeline-modal__title">${title}</h3>
      </header>
    `;
  }

  _renderContent() {
    return html`
      <section class="timeline-modal__content">
        <div class="timeline-modal__card">
          ${this._renderModalCard()}
        </div>
        <timeline-button class="timeline-modal__back-button" @click="${this._handleBackClick}">
          Zurück
        </timeline-button>
      </section>
    `;
  }

  _renderModalCard() {
    switch (this.aspect) {
      case MODAL_ASPECT_INFOS:
        return this._renderAspectInfos();
      case MODAL_ASPECT_DESCRIPTION:
        return this._renderAspectDescription();
      case MODAL_ASPECT_PAGE:
        return this._renderPageIframe();
      default:
        return html``;
    }
  }

  _renderAspectInfos() {
    let infos;

    if (this.entry.type === MODAL_ENTRY_TYPES.HOTEL) {
      infos = this._renderHotelInfos();
    } else if (this.entry.type === MODAL_ENTRY_TYPES.CAR) {
      infos = this._renderCarRentalInfos();
    } else if (MODAL_ENTRY_TYPES.getAdventureTypes().includes(this.entry.type)) {
      infos = this._renderAdventureInfos();
    } else {
      this._renderDefaultInfos();
    }

    return html`
      <h3 class="timeline-modal__card-title">${TimelineModal.mapEntryTypeToTitle(this.entry?.type)}</h3>
      ${infos}
    `;
  }

  _renderHotelInfos() {
    return html`
      <div class="timeline-modal__infos" with-background>
        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.CITY}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Name der Unterkunft</h4>
          <p class="timeline-modal__info-text">${this.entry?.headline}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.HOTEL}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Unterkunftsart</h4>
          <p class="timeline-modal__info-text">${this.entry?.subtitle}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.CALENDAR_MONTH}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Anreise/Abreise</h4>
          <p class="timeline-modal__info-text">${this.entry?.date}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.PEOPLE}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Gastnamen</h4>
          <p class="timeline-modal__info-text">
            ${unsafeHTML(this.entry?.infos)}
          </p>
        </div>
      </div>

      ${this.feature?.address || this.feature?.telephone || this.feature?.email ? this._renderHotelContacts() : ''}
    `;
  }

  _renderHotelContacts() {
    return html`
      <div class="timeline-modal__infos">

        ${this.feature?.address
    ? html`
            <div class="timeline-modal__info-group" is-fullwidth>
              <timeline-icon
                class="timeline-modal__info-icon"
                icon-name="${ICON_NAME.ADDRESS}"
                icon-size="${ICON_SIZE.M}">
              </timeline-icon>
              <h4 class="timeline-modal__info-title">Adresse</h4>
              <address class="timeline-modal__info-text">
                ${this.feature?.address?.streetAddress}, ${this.feature?.address?.postalCode} ${this.feature?.address?.addressLocality}
              </address>
            </div>
          ` : html``
}

        ${this.feature?.email
    ? html`
            <div class="timeline-modal__info-group">
              <timeline-icon
                class="timeline-modal__info-icon"
                icon-name="${ICON_NAME.EMAIL}"
                icon-size="${ICON_SIZE.M}">
              </timeline-icon>
              <h4 class="timeline-modal__info-title">Email</h4>
              <p class="timeline-modal__info-text">
                <a class="timeline-modal__info-link" href="mailto:${this.feature?.email}">
                  ${this.feature?.email}
                </a>
              </p>
            </div>
          ` : html``
}

        ${this.feature?.telephone
    ? html`
            <div class="timeline-modal__info-group">
              <timeline-icon
                class="timeline-modal__info-icon"
                icon-name="${ICON_NAME.PHONE}"
                icon-size="${ICON_SIZE.M}">
              </timeline-icon>
              <h4 class="timeline-modal__info-title">Telefon</h4>
              <p class="timeline-modal__info-text">
                <a class="timeline-modal__info-link" href="tel:${this.feature?.telephone}">
                  ${this.feature?.telephone}
                </a>
              </p>
            </div>
          ` : html``
}
      </div>
    `;
  }

  _renderCarRentalInfos() {
    let differentDeliveryStation = null;

    if (this.entry?.ref_2) {
      differentDeliveryStation = this.additionalFeatures
        .find((additionalFeature) => additionalFeature.identifier === this.entry.ref_2);
    }

    return html`
      <div class="timeline-modal__infos" with-background>
        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.CITY}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Autovermieter</h4>
          <p class="timeline-modal__info-text">${this.entry?.headline}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.CAR}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Fahrzeugtyp</h4>
          <p class="timeline-modal__info-text">${this.entry?.subtitle}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.CALENDAR_MONTH}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Anmietung/Abgabe</h4>
          <p class="timeline-modal__info-text">${this.entry?.date}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.PEOPLE}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Fahrer/in</h4>
          <p class="timeline-modal__info-text">
            ${unsafeHTML(this.entry?.infos)}
          </p>
        </div>
      </div>
      <h3 class="timeline-modal__card-headline">${differentDeliveryStation ? 'Mietwagen Annahme' : 'Mietwagen Annahme/Abgabe'}</h3>
      ${this.feature?.address || this.feature?.telephone || this.feature?.email ? this._renderCarRentalContacts(this.feature) : ''}
      ${differentDeliveryStation
    ? html`
          <h3 class="timeline-modal__card-headline">Mietwagen Abgabe</h3>
          ${this._renderCarRentalContacts(differentDeliveryStation)}
        `
    : html``
}
      ${this.entry?.remarks
    ? html`
          <div class="timeline-modal__infos" with-background is-single-column>
            ${unsafeHTML(this.entry?.remarks)}
          </div>
        ` : html``
}
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderCarRentalContacts(feature) {
    return html`
      <div class="timeline-modal__infos">

        ${feature?.address
    ? html`
            <div class="timeline-modal__info-group" is-fullwidth>
              <timeline-icon
                class="timeline-modal__info-icon"
                icon-name="${ICON_NAME.ADDRESS}"
                icon-size="${ICON_SIZE.M}">
              </timeline-icon>
              <h4 class="timeline-modal__info-title">Vermietstation</h4>
              <address class="timeline-modal__info-text">
                ${feature?.address?.streetAddress}, ${feature?.address?.postalCode} ${feature?.address?.addressLocality}
              </address>
            </div>
          ` : html``
}
        ${feature?.description
    ? html`
            <div class="timeline-modal__info-group" is-fullwidth>
              <timeline-icon
                class="timeline-modal__info-icon"
                icon-name="${ICON_NAME.INFO}"
                icon-size="${ICON_SIZE.M}">
              </timeline-icon>
              <h4 class="timeline-modal__info-title">Öffnungszeiten</h4>
              <address class="timeline-modal__info-text">
                ${unsafeHTML(feature.description)}
              </address>
            </div>
          ` : html``
}

        ${feature?.email
    ? html`
            <div class="timeline-modal__info-group">
              <timeline-icon
                class="timeline-modal__info-icon"
                icon-name="${ICON_NAME.EMAIL}"
                icon-size="${ICON_SIZE.M}">
              </timeline-icon>
              <h4 class="timeline-modal__info-title">Email</h4>
              <p class="timeline-modal__info-text">
                <a class="timeline-modal__info-link" href="mailto:${feature?.email}">
                  ${feature?.email}
                </a>
              </p>
            </div>
          ` : html``
}

        ${feature?.telephone
    ? html`
            <div class="timeline-modal__info-group">
              <timeline-icon
                class="timeline-modal__info-icon"
                icon-name="${ICON_NAME.PHONE}"
                icon-size="${ICON_SIZE.M}">
              </timeline-icon>
              <h4 class="timeline-modal__info-title">Telefon</h4>
              <p class="timeline-modal__info-text">
                <a class="timeline-modal__info-link" href="tel:${feature?.telephone}">
                  ${feature?.telephone}
                </a>
              </p>
            </div>
          ` : html``
}
      </div>
    `;
  }

  _renderDefaultInfos() {
    return html`
      <div class="timeline-modal__infos" is-single-column with-background>
        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.INFO}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Informationen</h4>
          <div class="timeline-modal__card-description">
            ${unsafeHTML(this.entry?.infos)}
          </div>
        </div>
      </div>
    `;
  }

  _renderAdventureInfos() {
    return html`
      <div class="timeline-modal__infos" with-background>
        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.CITY}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Anbieter</h4>
          <p class="timeline-modal__info-text">${this.entry?.headline}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.INFO}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Aktvität</h4>
          <p class="timeline-modal__info-text">${this.entry?.subtitle}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.CALENDAR_MONTH}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Tag/Uhrzeit</h4>
          <p class="timeline-modal__info-text">${this.entry?.date}</p>
        </div>

        <div class="timeline-modal__info-group">
          <timeline-icon
            class="timeline-modal__info-icon"
            icon-name="${ICON_NAME.PEOPLE}"
            icon-size="${ICON_SIZE.M}">
          </timeline-icon>
          <h4 class="timeline-modal__info-title">Gastnamen</h4>
          <p class="timeline-modal__info-text">
            ${unsafeHTML(this.entry?.infos)}
          </p>
        </div>
      </div>

      ${this.feature?.address || this.feature?.telephone || this.feature?.email ? this._renderHotelContacts() : ''}

      ${this.entry?.remarks
    ? html`
          <div class="timeline-modal__infos" with-background is-single-column>
            ${unsafeHTML(this.entry?.remarks)}
          </div>
        ` : html``
}
    `;
  }

  _renderAspectDescription() {
    return html`
      <h3 class="timeline-modal__card-title">${this.feature?.slogan}</h3>
      <div class="timeline-modal__card-description">
        ${unsafeHTML(this.feature?.description)}
      </div>
    `;
  }

  _renderPageIframe() {
    return html`
          <timeline-iframe-resizer>
            <iframe src="${this.entry.url}"
                    id="timeline_iframe"
            ></iframe>
          </timeline-iframe-resizer>
        `;
  }

  _handleBackClick() {
    this.dispatchEvent(new CustomEvent('close-modal', {
      bubbles: true,
      composed: true,
    }));
    this.dispatchEvent(new CustomEvent('stop-emergency-page', {
      bubbles: true,
      composed: true,
    }));
  }
}

window.customElements.define(TimelineModal.is, TimelineModal);
