import {
  concatMap, delay, of, tap,
} from 'rxjs';

/**
 * @param {string} groupId
 *
 * @return {boolean}
 */
export const isPastGroup = (groupId) => {
  if (!/(\d{4})_(\d{2})_(\d{2})/.test(groupId)) {
    return false;
  }

  const dateString = groupId.replaceAll('_', '-');
  const currentDate = new Date().setHours(0, 0, 0, 0);

  return new Date(dateString) < currentDate;
};

export const preFetchIframeContent = (menu) => {
  const allItems = menu.map((d) => d.children).flat();
  of(...allItems).pipe(
    concatMap((x) => of(x).pipe(
      tap((item) => {
        const iframe = document.createElement('iframe');

        iframe.setAttribute('src', item.url);
        iframe.style.width = '1px';
        iframe.style.height = '1px';
        iframe.style.visibility = 'hidden';
        iframe.id = 'prefetch-iframe';
        document.body.appendChild(iframe);
      }),
      delay(5000),
      tap(() => document.getElementById('prefetch-iframe').remove()),
    )),
  ).subscribe();
};
