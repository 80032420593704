export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const SPINNER_SHOW = 'SPINNER_SHOW';
export const SPINNER_HIDE = 'SPINNER_HIDE';

export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export const OPEN_ACTIONBAR_PANEL = 'OPEN_ACTIONBAR_PANEL';
export const CLOSE_ACTIONBAR_PANEL = 'CLOSE_ACTIONBAR_PANEL';

export const SET_MOBILE_FALSE = 'SET_MOBILE_FALSE';
export const SET_MOBILE_TRUE = 'SET_MOBILE_TRUE';
