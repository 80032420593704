import {
  SET_CURRENT_PAGE,
  SPINNER_HIDE,
  SPINNER_SHOW,
  CLOSE_MENU,
  OPEN_MENU,
  OPEN_ACTIONBAR_PANEL,
  CLOSE_ACTIONBAR_PANEL,
  SET_MOBILE_FALSE,
  SET_MOBILE_TRUE,
} from '../constants/app';

const initialState = {
  currentPage: 'login',
  emergencyPage: false,
  mobile: false,
  openFilters: false,
  openMenu: false,
  actionbarPanelIsOpen: false,
  openModal: false,
  spinnerVisible: false,
};
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SPINNER_SHOW:
      return {
        ...state,
        spinnerVisible: true,
      };
    case SPINNER_HIDE:
      return {
        ...state,
        spinnerVisible: false,
      };
    case OPEN_MENU:
      return {
        ...state,
        openMenu: true,
      };
    case CLOSE_MENU:
      return {
        ...state,
        openMenu: false,
      };
    case OPEN_ACTIONBAR_PANEL:
      return {
        ...state,
        actionbarPanelIsOpen: true,
      };
    case CLOSE_ACTIONBAR_PANEL:
      return {
        ...state,
        actionbarPanelIsOpen: false,
      };
    case SET_MOBILE_TRUE:
      return {
        ...state,
        mobile: true,
      };
    case SET_MOBILE_FALSE:
      return {
        ...state,
        mobile: false,
      };
    default:
      return state;
  }
}
