import { LitElement, html } from 'lit';

import '../timeline-topbar/timeline-topbar';
import '../timeline-header/timeline-header';
import '../timeline-teaser/timeline-teaser';
import '../timeline-group/timeline-group';
import '../timeline-map/timeline-map';
import '../timeline-map/timeline-map-marker';
import '../timeline-map/timeline-map-popup';
import '../timeline-map/timeline-map-geojson';
import '../timeline-map/timeline-map-layer';
import '../timeline-map/timeline-map-wrapper';
import '../timeline-login/timeline-login';
import '../timeline-filters/timeline-filters';
import '../timeline-separator/timeline-separator';
import '../timeline-modal/timeline-modal';
import '../timeline-actionbar/timeline-actionbar';
import '../timeline-actionbar-panel/timeline-actionbar-panel';
import '../timeline-notifications/timeline-notifications';
import '../timeline-button/timeline-button';
import '../timeline-icon/timeline-icon';

import { connect } from 'pwa-helpers';
import { store } from '../../store';

import { timelineLogout } from '../../auth';
import '../../navigation';

import {
  openGroup,
  closeGroup,
  toggleTodo,
} from '../../redux/actions/timeline';

import {
  openMenu,
  closeMenu,
  spinnerShow,
  spinnerHide,
  setMobileTrue,
  setMobileFalse,
  openActionbarPanel,
  closeActionbarPanel,
} from '../../redux/actions/app';
import {
  setTeaserToHighlight, unsetTeaserToHighlight, setOpenPopupID,
  unsetOpenPopupID, showMobileMap,
  hideMobileMap,
} from '../../redux/actions/map';

import styles from './timeline-container.css';
import { closeModal, openModal } from '../../redux/actions/modal';
import { isPastGroup } from '../../lib/utils';

class TimelineContainer extends connect(store)(LitElement) {
  /**
   * Get separator position
   * Show either the login or timeline views
   */
  render() {
    this._getSeparatorPosition();

    if (this.auth.loggedIn) {
      return this._renderTimeline();
    }

    return this._renderLogin();
  }

  static get is() {
    return 'timeline-container';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      app: {
        type: Object,
      },
      entries: {
        type: Array,
      },
      timeline: {
        type: Object,
      },
      pages: {
        type: Object,
      },
      timeline_state: {
        type: Object,
      },
      openGroups: {
        type: Array,
      },
      auth: {
        type: Object,
      },
      map: {
        type: Object,
      },
      timelineFeatures: {
        type: Object,
      },
      modal: {
        type: Object,
      },
      menuSections: {
        type: Array,
      },
    };
  }

  /**
   * Define default values for some element"s properties
   * Define event listeners
   */
  constructor() {
    super();
    this.timeline = {
      title: 'Loading',
      headline: 'Timeline Title',
    };
    this.timeline.entries = [];
    this.timeline.groups = [];
    this.openGroups = [];

    this.pageToRender = {};
    this.modalContent = {};
    this.timeline_state = {};
    this.oldVisibleMapFeatures = 'timeline';
    this.menuSections = [];

    // menu events
    this.addEventListener('open-menu', this._handleOpenMenu);
    this.addEventListener('close-menu', this._handleCloseMenu);
    this.addEventListener('log-out', this._handleLogOut);
    // tödo events
    this.addEventListener('check-todo', this._handleCheckTodo);
    this.addEventListener('uncheck-todo', this._handleUncheckTodo);
    // group events
    this.addEventListener('open-group', this._handleOpenGroup);
    this.addEventListener('close-group', this._handleCloseGroup);

    // teaser events
    this.addEventListener('set-teaser-to-highlight', this._handleSetTeaserToHighlight);
    this.addEventListener('unset-teaser-to-highlight', this._handleUnsetTeaserToHighlight);

    // modal events
    this.addEventListener('open-modal', this._handleOpenModal);
    this.addEventListener('close-modal', this._handleCloseModal);

    // actionbar events
    this.addEventListener('show-mobile-map', this._handleShowMobileMap);
    this.addEventListener('hide-mobile-map', this._handleHideMobileMap);
    // auth events
    this.addEventListener('add-error-message', this._handleAddErrorMessage);

    // spinner
    this.addEventListener('spinner-show', this._handleSpinnerShow);
    this.addEventListener('spinner-hide', this._handleSpinnerhide);

    // map
    this.addEventListener('set-open-popup-id', this._handleSetOpenPopupID);
    this.addEventListener('unset-open-popup-id', this._handleUnsetOpenPopupID);

    // actionbar panel
    this.addEventListener('open-actionbar-panel', this._handleOpenActionbarPanel);
    this.addEventListener('close-actionbar-panel', this._handleCloseActionbarPanel);

    // notifications
    this.addEventListener('dismiss-notification', this._handleDismissNotification);
    this.addEventListener('open-notifications', this._handleOpenNotifications);
    this.addEventListener('close-notifications', this._handleCloseNotifications);
    // window.addEventListener('add-notification', this._handleAddNotification);
    // window.addEventListener('add-user-messaging-token', this._handleAddUserMessagingToken);
  }

  /**
   * If the state changed, update data so the element / content also updates
   * Save changes to local storage
   */
  stateChanged(state) {
    this.app = state.app;
    this.auth = state.auth;
    this.timeline = state.timeline.timeline;
    this.timelineFeatures = state.timeline.features;
    this.openGroups = state.timeline.openGroups;
    this.map = state.map;
    this.modal = state.modal;
    this.menuSections = state.destinationPages.pages;
  }

  /**
   * Once the element is connected,
   * check for window width and siplay correct view
   */
  connectedCallback() {
    super.connectedCallback();

    // Add Breakpoint to change between mobile and desktop layouts
    this._mobileBreakpoint = window.matchMedia('(max-width: 60rem)');
    this._handleBreakpointChange(this._mobileBreakpoint);
    this._mobileBreakpoint.addListener((event) => this._handleBreakpointChange(event));
  }

  /**
   * Register groups
   * Populate groups with entries
   */
  // eslint-disable-next-line consistent-return
  _getGroupEntries(groupID) {
    return this.timeline.entries.filter((entry) => entry.group_id.includes(groupID));
  }

  /**
   * Go through all the groups to find the correct position for the separator
   */
  _getSeparatorPosition() {
    const allGroups = this.shadowRoot.querySelectorAll('timeline-group');
    const pastGroups = this.shadowRoot.querySelectorAll('timeline-group[ispast]');
    const lastPastGroup = pastGroups[pastGroups.length - 1];
    if (lastPastGroup && lastPastGroup.nextSibling.nodeName !== 'TIMELINE-SEPARATOR' && allGroups.length !== pastGroups.length) {
      lastPastGroup.insertAdjacentHTML('afterend', '<timeline-separator></timeline-separator>');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _browserSupportsPushAPI() {
    return 'PushManager' in window;
  }

  // HANDLE EVENTS
  // eslint-disable-next-line class-methods-use-this
  _handleAddErrorMessage(error) {
    console.log('add-error-message:', error);
  }

  /**
   * If the browser window changes size, check if mobile or desktop
   * and set correct settings in store
   */
  // eslint-disable-next-line class-methods-use-this
  _handleBreakpointChange(event) {
    if (event.matches) {
      store.dispatch(setMobileTrue());
    } else {
      store.dispatch(setMobileFalse());
      store.dispatch(hideMobileMap());
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _handleOpenMenu() {
    store.dispatch(openMenu());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleCloseMenu() {
    store.dispatch(closeMenu());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleLogOut() {
    timelineLogout();
  }

  // eslint-disable-next-line class-methods-use-this
  _handleOpenGroup(event) {
    store.dispatch(openGroup(event.detail));
  }

  // eslint-disable-next-line class-methods-use-this
  _handleCloseGroup(event) {
    store.dispatch(closeGroup(event.detail));
  }

  // eslint-disable-next-line class-methods-use-this
  _handleCheckTodo(event) {
    store.dispatch(toggleTodo(event.detail, true));
  }

  // eslint-disable-next-line class-methods-use-this
  _handleUncheckTodo(event) {
    store.dispatch(toggleTodo(event.detail, false));
  }

  // eslint-disable-next-line class-methods-use-this
  _handleShowMobileMap() {
    store.dispatch(showMobileMap());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleHideMobileMap() {
    store.dispatch(hideMobileMap());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleSetOpenPopupID(event) {
    store.dispatch(setOpenPopupID(event.detail.teaserID));
  }

  // eslint-disable-next-line class-methods-use-this
  _handleUnsetOpenPopupID() {
    store.dispatch(unsetOpenPopupID());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleSetTeaserToHighlight(event) {
    store.dispatch(setTeaserToHighlight(event.detail));
  }

  // eslint-disable-next-line class-methods-use-this
  _handleUnsetTeaserToHighlight() {
    store.dispatch(unsetTeaserToHighlight());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleSpinnerShow() {
    store.dispatch(spinnerShow());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleSpinnerhide() {
    store.dispatch(spinnerHide());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleDismissNotification(event) {
    console.log('todo: _handleDismissNotification', event);
  }

  // eslint-disable-next-line class-methods-use-this
  _handleOpenNotifications() {
    console.log('todo: _handleOpenNotifications');
  }

  // eslint-disable-next-line class-methods-use-this
  _handleCloseNotifications() {
    console.log('todo: _handleCloseNotifications');
  }

  // eslint-disable-next-line class-methods-use-this
  _handleOpenModal(event) {
    if (event.detail.entry.ref_2 !== null) {
      // eslint-disable-next-line no-param-reassign
      event.detail.additionalFeatures = [this.timelineFeatures[event.detail.entry.ref_2]];
    }

    store.dispatch(openModal(event.detail));
  }

  // eslint-disable-next-line class-methods-use-this
  _handleCloseModal() {
    store.dispatch(closeModal());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleOpenActionbarPanel() {
    store.dispatch(openActionbarPanel());
  }

  // eslint-disable-next-line class-methods-use-this
  _handleCloseActionbarPanel() {
    store.dispatch(closeActionbarPanel());
  }

  /**
   * Scroll until the first teaser which is not 'in the past'
   */
  _scrollToToday() {
    const today = this.shadowRoot.querySelector('timeline-separator');
    if (today) {
      const todayTop = today.offsetTop - 24; // -24px to account for topbar height
      window.scrollTo({ top: todayTop, behavior: 'smooth' });
    }
  }

  /**
   * Render whole timeline app (excluding the login screen)
   * if needed, render also:
   *    - modal
   *    - admin notification
   *    - map
   *    - menu
   *    - filters
   */
  _renderTimeline() {
    return html`
      <timeline-topbar
       currentPage="${this.app.currentPage}"
       role="region"
       aria-label="Topbar with menu toggle, logo and extra functions like notifications, share and filters"
       .openMenu="${this.app.openMenu}"
       .openModal="${this.modal.open}"
       .menuSections="${this.menuSections}"
       .notificationsAmount="${this.app?.notifications?.length ?? 0}"
       .notificationsOpen="${this.app?.notificationsOpen}"
       .mapVisible="${this.map.mapView}"
       ?appmobile="${this.app?.mobile}"
       ?openFilters="${this.app.openFilters}"
      ></timeline-topbar>
      <timeline-filters ?openFilters="${this.app.openFilters}"
                        role="region"
                        aria-label="List of buttons to filter the timeline"
                        aria-hidden="${!this.app.openFilters}"
      ></timeline-filters>
      <div class="timeline-container__columns">
        <div class="timeline-container__column-left"
             ?mapview="${this.map.mapView}"
             role="region"
             aria-label="Content Column"
        >
          <timeline-header headline="${this.timeline.headline}"
                           period="${this.timeline.period}"
                           image="${this.timeline.header_image}"
                           role="banner"
          ></timeline-header>
          <div class="timeline-container__groups-container">
            ${this.timeline.groups?.map((group) => html`
              <timeline-group .group="${group}"
                              .entries="${this._getGroupEntries(group.group_id)}"
                              group-name="${group.group_id}"
                              ?open="${this.openGroups.includes(group.group_id)}"
                              ?ispast="${isPastGroup(group.group_id)}"
                              groupDate=""
                              .teaserToHighlight="${this.map.teaserToHighlight}"
                              .features="${this.timelineFeatures}"
                              .teaserWithOpenPopup="${this.app.mapOpenPopupID}"
                              role="region"
                              aria-expanded="${this.openGroups.includes(group.group_id)}"></timeline-group>
            `)}
          </div>
        </div> <!-- end .timeline-container__column-left -->

      <!-- right Column: Map -->
      <div class="timeline-container__column-right" ?mapview="${this.map.mapView}" role="region" aria-label="Map">

        <!-- initial bounds order: west, south, east, north-->
        ${this.map?.mapFeatures ? html`
          <timeline-map-wrapper .mapBounds="${this.map.mapBounds}"
                                .zoomOnScroll="${this.map.zoomOnScroll}"
                                .featureCollection="${this.map.mapFeatures}"
                                .featureToHighlight="${this.map?.teaserToHighlight}"
                                .openPopupId="${this.map?.openPopupId}"
          ></timeline-map-wrapper>
        ` : ''}
      </div> <!-- end .timeline-container__column-right-->

      <!-- modal -->
      <timeline-modal
        ?openmodal="${this.modal.open}"
        .aspect="${this.modal.aspect}"
        .entry="${this.modal.entry}"
        .feature="${this.modal.feature}"
        .additionalFeatures="${this.modal.additionalFeatures}"
        ?loading="${this.modal.loading}"
        role="region"
        aria-hidden="${!this.modal.open}"
      ></timeline-modal>
    </div>
    <!-- actionbar -->
    ${this.app.mobile
    ? html`
        <timeline-actionbar
          role="region"
          aria-hidden="${!this.app.mapView}"
          .mapView="${this.map.mapView}"
          .actionbarPanelIsOpen="${this.app.actionbarPanelIsOpen}"
          .menuSections="${this.menuSections}"
          ?onPage="${this.app.modalType === 'page'}"
        ></timeline-actionbar>`
    : ''
}

    <!-- notifications -->
    ${this._browserSupportsPushAPI()
    ? html`<timeline-notifications .notificationsLoading="${this.app.notificationsLoading}" .notifications="${this.app.notifications}" ?notificationsOpen="${this.app.notificationsOpen}" role="region" aria-hidden="${!this.app.notificationsOpen}"></timeline-notifications>`
    : ''
}
  `;
  }

  _renderLogin() {
    return html`<timeline-login
      loginErrorMessage="${this.auth.loginErrorMessage}"
      ?falseLogin="${this.auth.loginErrorMessage}"
      .showSpinner="${this.app.spinnerVisible}"
    ></timeline-login>`;
  }
}

window.customElements.define(TimelineContainer.is, TimelineContainer);
