import {
  ADD_LOGIN_ERROR_MESSAGE,
  SET_LOGGED_IN, SET_LOGGED_OUT,
} from '../constants/auth';

const initialState = {
  loginErrorMessage: '',
  loggedIn: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_LOGIN_ERROR_MESSAGE:
      return {
        ...state,
        loginErrorMessage: action.payload,
      };
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: true,
        loginErrorMessage: '',
      };

    case SET_LOGGED_OUT:
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }
}
