import { LitElement, html } from 'lit';
import styles from './timeline-actionbar-panel.css';
import { ICON_NAME, ICON_SIZE } from '../../types/icon-types';

class TimelineActionbarPanel extends LitElement {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return html`
      <button class="timeline-actionbar-panel__close" @click="${this._onCloseClick}">
        <timeline-icon class="timeline-actionbar-panel__close-icon" icon-name="${ICON_NAME.CLOSE}" icon-size="${ICON_SIZE.MS}"></timeline-icon>
      </button>

      <ul class="timeline-actionbar-panel__list">
        ${this.tripGuideLinks.map((link) => html`
          <li class="timeline-actionbar-panel__item">
            <span linkHref="${link.url}" class="timeline-actionbar-panel__link" @click="${this._handlePdfLinkClick}">
              <timeline-icon class="timeline-actionbar-panel__pdf-icon" icon-name="${ICON_NAME.PDF}" icon-size="${ICON_SIZE.M}"></timeline-icon>
              ${link.title}
            </span>
          </li>
        `)}
      </ul>
    `;
  }

  static get is() {
    return 'timeline-actionbar-panel';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      isVisible: {
        type: Boolean,
        reflect: true,
        attribute: 'is-visible',
      },
      tripGuideLinks: {
        type: Array,
      },
      actionbarPanelIsOpen: {
        type: Boolean,
      },
    };
  }

  _onCloseClick() {
    this.dispatchEvent(new CustomEvent('close-actionbar-panel', {
      bubbles: true,
      composed: true,
    }));
  }

  _handlePdfLinkClick(event) {
    const clickedItem = event.currentTarget;
    const hrefAttr = clickedItem?.getAttribute('linkHref');
    if (hrefAttr) {
      window.open(hrefAttr);
    }

    this.dispatchEvent(new CustomEvent('external-link-click', {
      bubbles: true,
      composed: true,
    }));

    this.dispatchEvent(new CustomEvent('close-actionbar-panel', {
      bubbles: true,
      composed: true,
    }));
  }
}

window.customElements.define(TimelineActionbarPanel.is, TimelineActionbarPanel);
