export const MODAL_ENTRY_TYPES = {
  CAR: 'car',
  HOTEL: 'hotel',
  NATURE: 'nature',

  BICYCLE: 'bicycle',
  BIRD_WATCHING: 'bird-watching',
  BOAT_TOUR: 'boat-tour',
  CANOEING: 'canoeing',
  CANYONING: 'canyoning',
  DIVING: 'diving',
  DOLPHINS: 'dolphins',
  GOLF: 'golf',
  GUIDED_TOUR: 'guided-tour',
  HIKING: 'hiking',
  KAYAKING: 'kayaking',
  RIDING: 'riding',
  SAILING: 'sailing',
  WHALEWATCHING: 'whalewatching',
  YOGA: 'yoga',

  getAdventureTypes: () => [
    MODAL_ENTRY_TYPES.BICYCLE,
    MODAL_ENTRY_TYPES.BIRD_WATCHING,
    MODAL_ENTRY_TYPES.BOAT_TOUR,
    MODAL_ENTRY_TYPES.CANOEING,
    MODAL_ENTRY_TYPES.CANYONING,
    MODAL_ENTRY_TYPES.DIVING,
    MODAL_ENTRY_TYPES.DOLPHINS,
    MODAL_ENTRY_TYPES.GOLF,
    MODAL_ENTRY_TYPES.GUIDED_TOUR,
    MODAL_ENTRY_TYPES.HIKING,
    MODAL_ENTRY_TYPES.SAILING,
    MODAL_ENTRY_TYPES.WHALEWATCHING,
    MODAL_ENTRY_TYPES.YOGA,
  ],
};
