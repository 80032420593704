import { LitElement, html } from 'lit';
import styles from './timeline-menu.css';
import { MODAL_ASPECT_PAGE } from '../../types/modal-aspect';
import { ICON_NAME, ICON_SIZE } from '../../types/icon-types';
import { MENU_ITEM_DOKTYPE } from '../../types/menu-item-types';

class TimelineMenu extends LitElement {
  render() {
    return html`
        <button @click="${this._buttonClick}" class="timeline-menu__menu-button" role="button" aria-label="Menu toggle button" aria-expanded="${this.open}">
          ${this._renderMenuIcon()}
        </button>

        <nav class="timeline-menu__menu-container">
          ${this.menuSections.map((menuSection) => {
    if (!menuSection.children.length) {
      return undefined;
    }

    return this._renderMenuSection(menuSection);
  })}

          <!-- Log out button -->
          <ul class="timeline-menu__menu-list timeline-menu__menu-list--logout">
            <li class="timeline-menu__menu-item">
              <timeline-button
                class="timeline-menu__menu-content timeline-menu__menu-content--no-padding"
                button-variant="secondary"
                @click="${this._handleLogOutClick}"
                targetpage="logout"
                ?currentpage="${this.currentPage === 'logout'}"
              >Logout</timeline-button>
            </li>
          </ul>
        </nav>

      <div class="timeline-menu__overlay" @click="${this._handleOverlayClick}" aria-hidden="true"></div>
    `;
  }

  static get is() {
    return 'timeline-menu';
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      open: {
        type: Boolean,
      },
      openModal: {
        type: Boolean,
      },
      currentPage: {
        type: String,
      },
      menuSections: {
        type: Array,
      },
    };
  }

  /**
   * Constructor function
   * set default values for some of the element's properties
   */
  constructor() {
    super();
    this.open = false;
    this.openModal = false;
    this.currentPage = 'test';
    this.menuSections = [];
  }

  /**
   * Add event listeners to the menu items
   */
  firstUpdated() {
    const menuItemsWithChildren = this.shadowRoot.querySelectorAll('.timeline-menu__menu-item--with-children');
    menuItemsWithChildren.forEach((menuItem) => {
      menuItem.addEventListener('click', this._handleMenuItemClick);
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('open')) {
      document.querySelector('body').classList.toggle('menu-open', this.open);
    }
  }

  _handleMenuItemClick(menu) {
    this.dispatchEvent(new CustomEvent('close-menu', {
      bubbles: true,
      composed: true,
    }));

    this.dispatchEvent(new CustomEvent('open-modal', {
      bubbles: true,
      composed: true,
      detail: {
        entry: menu,
        aspect: MODAL_ASPECT_PAGE,
      },
    }));
  }

  /**
   * Open click on external "link" only on a new tab - prevent iFrame from loading
   * @param event
   * @private
   */
  _handlePdfLinkClick(event) {
    const clickedItem = event.currentTarget;
    const hrefAttr = clickedItem?.getAttribute('linkHref');
    if (hrefAttr) {
      window.open(hrefAttr);
    }

    this.dispatchEvent(new CustomEvent('external-link-click', {
      bubbles: true,
      composed: true,
    }));
  }

  /**
   * Close menu on layover click
   */
  _handleOverlayClick() {
    this.dispatchEvent(new CustomEvent('close-menu', {
      bubbles: true,
      composed: true,
    }));
  }

  /**
   * Define different actions when the menu buttons is clicked
   * The button needs different actions because:
   *    - sometimes the button open a menu
   *    - sometimes it closes the menu
   *    - sometimes it works as a 'back' button
   * the button changes according to the context / page it is open
   */
  _buttonClick() {
    this.dispatchEvent(new CustomEvent(this.open ? 'close-menu' : 'open-menu', {
      bubbles: true,
      composed: true,
    }));

    this.dispatchEvent(new CustomEvent('close-filters', {
      bubbles: true,
      composed: true,
    }));
    this.dispatchEvent(new CustomEvent('close-actionbar-panel', {
      bubbles: true,
      composed: true,
    }));
    this.dispatchEvent(new CustomEvent('close-notifications', {
      bubbles: true,
      composed: true,
    }));

    this.open = !this.open;
  }

  /**
   * Start log out process
   * close the menu
   */
  _handleLogOutClick() {
    this.dispatchEvent(new CustomEvent('log-out', {
      bubbles: true,
      composed: true,
    }));
    this.dispatchEvent(new CustomEvent('close-menu', {
      bubbles: true,
      composed: true,
    }));
  }

  _renderMenuIcon() {
    const iconName = this.open ? ICON_NAME.CLOSE : ICON_NAME.BURGER;

    return html`
      <timeline-icon class="timeline-menu__menu-icon" icon-name="${iconName}" icon-size="${ICON_SIZE.XL}"></timeline-icon>
    `;
  }

  _renderMenuSection(menuSection) {
    return html`
      <ul class="timeline-menu__menu-list">
        <li class="timeline-menu__menu-header">
          <h4 class="timeline-menu__menu-list-title" @click="${this._handleMenuToggle}">${menuSection.title}</h4>
          <span class="timeline-menu__list-chevron-container" @click="${this._handleMenuToggle}">
            <timeline-icon class="timeline-menu__list-chevron" icon-name="${ICON_NAME.CHEVRON_DOWN}" icon-size="${ICON_SIZE.XL}"></timeline-icon>
          </span>
        </li>

        ${(menuSection.children ?? []).map((menuPoint) => html`
          <li class="timeline-menu__menu-item">
            ${menuPoint.doktype === MENU_ITEM_DOKTYPE.LINK && menuPoint.url
    ? html`
      <span class="timeline-menu__menu-content">
        <span linkHref="${menuPoint.url}" class="timeline-menu__pdf-link" @click="${this._handlePdfLinkClick}">
            ${menuPoint.title}
            <timeline-icon class="timeline-menu__pdf-icon" icon-name="${ICON_NAME.PDF}" icon-size="${ICON_SIZE.M}"></timeline-icon>
        </span>
      </span>
    `
    : html`
      <span class="timeline-menu__menu-content" @click="${() => this._handleMenuItemClick(menuPoint)}">${menuPoint.title}</span>
    `
}
        </li>
        `)}
      </ul>
    `;
  }

  /**
   * Toggle menu section open or closed
   */
  // eslint-disable-next-line class-methods-use-this
  _handleMenuToggle(event) {
    event.target.closest('.timeline-menu__menu-list').toggleAttribute('menu-section-closed');
  }
}

window.customElements.define(TimelineMenu.is, TimelineMenu);
